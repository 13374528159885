import theme from 'theme/theme';

import { IconsType } from './icon.types';

const ArrowUp = ({
  width = 21,
  height = 13,
  color = theme.colors.White,
  ...props
}: IconsType) => (
  <svg fill='none' width={width} height={height} viewBox='0 0 21 13' {...props}>
    <path
      fill={color}
      d='M20.3584 8.30803L20.3584 13L10.1792 5.60418L0 13L6.15278e-07 8.30803L10.1792 0.912209L20.3584 8.30803Z'
    />
  </svg>
);

export default ArrowUp;
