import { ChangeEventHandler, HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';

import theme from 'theme/theme';
import { getRem } from 'utils/functions';

type TextInputProps = {
  className?: string;
  placeholder?: string;
  value: string | number;
  type?: HTMLInputTypeAttribute;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

const TextInput = ({ value, type, placeholder, onChange }: TextInputProps) => {
  return (
    <Input
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

const Input = styled.input`
  font-family: Roboto, san-serif;
  width: 100%;
  text-indent: 30px;
  height: ${getRem(49)};
  font-size: ${(props) => props.theme.fontSizes.fz18};
  border: none;
  font-weight: 300 !important;
  line-height: 21.5px;
  border-radius: 0 !important;

  &::placeholder {
    opacity: 0.4;
    font-family: inherit;
    color: ${(props) => props.theme.colors.Black};
    font-size: ${(props) => props.theme.fontSizes.fz18};
    font-weight: 300 !important;
    line-height: 21.5px;
  }

  @media ${theme.deviceSizes.tablet} {
    font-size: ${(props) => props.theme.fontSizes.fz14};
    text-indent: 16px;
    line-height: 18px;

    &::placeholder {
      font-size: ${(props) => props.theme.fontSizes.fz14};
  }
  }
`;

export default TextInput;
