
import ContactUsWelcome from './components/ContactUsWelcome';

const ContactUs = () => {
  return (
    <>
      <ContactUsWelcome />
    </>
  );
};

export default ContactUs;
