import {useState, useEffect, useRef} from 'react';
import {isLandscape} from '../functions';
import {ORIENTATION} from '../enums';

const useViewport = () => {
  const resizeLag: any = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [orientation, setOrientation] = useState(isLandscape() ? ORIENTATION.landscape : ORIENTATION.portrait);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
    clearTimeout(resizeLag.current);
    resizeLag.current = setTimeout(() => {
      setOrientation(isLandscape() ? ORIENTATION.landscape : ORIENTATION.portrait)
    }, 200);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width, height, orientation };
};

export default useViewport;
