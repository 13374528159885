import {AnimatePresence} from 'framer-motion';
import {useEffect, useMemo, useRef, useState,} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {privateRoutes} from 'app/Routes';
import {RouteTypes} from 'app/types';
import Logo from 'components/assets/images/zero5_logo.png';
import MenuBurgerIcon from 'components/icons/MenuBurger.icon';
import MenuBurgerOpen from 'components/icons/MenuBurgerOpen.icon';
import MobileMenu from './components/MobileMenu';
import {HEADER_HEIGHT, heightMobileBreakpoints, viewportBreakpoints} from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import {SHeader} from './SHeader';
import {ORIENTATION} from 'utils/enums';

type HeaderProps = {
  isLoginPage: boolean;
};

const Header = ({isLoginPage}: HeaderProps) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(false);
  const [isFixedPosition, setIsFixedPosition] = useState<boolean>(false);
  const isAuth = true;

  const headerRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { width, height, orientation } = useViewport();

  const isMobile = useMemo(
    () => width <= parseInt(viewportBreakpoints.tablet),
    [width]
  );

  const isLandscape = useMemo(
    () => (width <= parseInt(viewportBreakpoints.laptop) && (height < heightMobileBreakpoints && orientation === ORIENTATION.landscape)),
    [width, orientation, height]
  );


  const navLinks = useMemo(() => {
    return privateRoutes.filter(
      (el) => el.route !== RouteTypes.default && el.route !== RouteTypes.login
    );
  }, []);



  useEffect(() => {
    setMenuToggle(false);
  }, [location]);

  useEffect(() => {
    const scrollFunction = () => {
      if (
        !isFixedPosition &&
        window.scrollY + 25 >= (headerRef.current?.clientHeight || 0)
      ) {
        setIsFixedPosition(true);
      }

      if (
        isFixedPosition &&
        window.scrollY + 25 < (headerRef.current?.clientHeight || 0)
      ) {
        setIsFixedPosition(false);
      }
    };

    window.addEventListener('scroll', scrollFunction);

    return () => {
      window.removeEventListener('scroll', scrollFunction);
    };
  }, [isFixedPosition, width]);

  return (
    <>
      <SHeader.Container
        className='header-top-line'
        ref={headerRef}
        style={{
          top: (isMobile || isLandscape) ? 0 : isFixedPosition ? 0 : '78px',
          position: (isMobile || isLandscape) || isFixedPosition ? 'fixed' : 'absolute',
          borderTop: isLoginPage ? 'none' : '',
        }}
      >
        <Link to={RouteTypes.default}>
          <SHeader.Image src={Logo} alt='Logo' />
        </Link>
        {isAuth ? (
          <>
            {<SHeader.NavSection style={{display: isLoginPage || (isMobile || isLandscape) ? 'none' : 'inherit'}}>
              {navLinks.map((el, i) => {
                return (
                  <li key={i}>
                    <SHeader.NavLink
                      to={el.route}
                      className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                      {el.name}
                    </SHeader.NavLink>
                  </li>
                );
              })}

              <SHeader.VL />

              <SHeader.LoginWrapper>
                <SHeader.NavLink isLoginLink to='/login'>Login</SHeader.NavLink>
              </SHeader.LoginWrapper>
            </SHeader.NavSection>}
            <SHeader.BurgerContainer
              onClick={() => {
                setMenuToggle(!menuToggle);
              }}
            >
              {!menuToggle ? <MenuBurgerIcon /> : <MenuBurgerOpen />}
            </SHeader.BurgerContainer>
          </>
        ) : null}
      </SHeader.Container>
      <>
        <AnimatePresence>
          {menuToggle ? (
            <SHeader.ContainerMobileMenu
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, x: -200, transition: { duration: 0.5 } }}
              style={{
                top: (isMobile || isLandscape) ? 0 : isFixedPosition ? 0 : HEADER_HEIGHT,
                position: (isMobile || isLandscape) || isFixedPosition ? 'fixed' : 'absolute',
              }}
            >
              <MobileMenu />
            </SHeader.ContainerMobileMenu>
          ) : null}
        </AnimatePresence>
      </>
    </>
  );
};

export default Header;
