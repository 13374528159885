/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';


const TermsOfUse: React.FC = () => {
  React.useEffect(() => {
    window.history.scrollRestoration = 'manual';

    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <MainHeading>Terms of Use</MainHeading>
      <Text>Last modified: 11/19/2021</Text>
      <div>
        <Heading as="h3">1.	Acceptance of the Terms of Use</Heading>
        <Text>
          These terms of use are entered into by and between you and zero5, Inc. (“zero5”, “we” or “us”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, these “Terms of Use”), govern your access to and use of zero5.co or subdomains thereof, including any content, functionality and services offered on or through zero5.co (each, a “Website”), which enables parking facility owners or operators (“Parking Facility Operator”) that use our platform manage their parking facility and enables end users (“End Users”) to sign up for and use our platform to manage payment for parking at our locations. Use of zero5’s goods and services, including the access and use of our Website, by Parking Facility Operators may also be governed by additional terms and conditions set forth in our agreement for services. Your use of any mobile applications used to access the same or similar functionality as the Websites is also governed by our Mobile Application End User License Agreement, which is available when you download and use one of our mobile applications.
        </Text>
        <Text>
          Please read the Terms of Use carefully before you start to use the Websites. By using the Websites or by clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, incorporated herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the Websites.
        </Text>
        <Text>
          The Websites are offered and available to users who are 13 years of age or older and reside in the United States or any of its territories or possessions. By using the Websites, you represent and warrant that you are of legal age to form a binding contract with zero5, or, if you are below the age to form a binding contract, that you have your parent or guardian’s authorization to use the Websites, and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Websites.
        </Text>
        <Heading as="h3">2.	Changes to the Terms of Use</Heading>
        <Text>
          We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Websites thereafter.
        </Text>
        <Text>
          Your continued use of the Websites following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.
        </Text>
        <Heading as="h3">3.	Accessing the Websites and Account Security</Heading>
        <Text>
          We reserve the right to withdraw or amend the Websites, and any service or material we provide on the Websites, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Websites is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Websites, or the entire Websites, to users, including registered users.
        </Text>
        <Text>
          You are responsible for:
        </Text>
        <List>
          <ListItem>
            Making all arrangements necessary for you to have access to the Websites.
          </ListItem>
          <ListItem>
            Ensuring that all persons who access the Websites through your internet connection are aware of these Terms of Use and comply with them.
          </ListItem>
        </List>
        <Text>
          To access the Websites or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Websites that all the information you provide on the Websites is correct, current and complete. You agree that all information you provide to register with the Websites or otherwise, including but not limited to through the use of any interactive features on the Websites, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.
        </Text>
        <Text>
          If you choose, or are provided with, a username, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Websites or portions of it using your username, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.
        </Text>
        <Text>
          We have the right to disable any username, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.
        </Text>
        <Heading as="h3">4.	Registration Obligations</Heading>
        <Text>
          In consideration of your use of the Website, you agree to: (a) provide information about yourself that is true, accurate, current, and complete (the “User-Provided Information”) and (b) where applicable and appropriate, maintain and promptly update the User-Provided Information to keep it true, accurate, current, and complete. If you provide information that is untrue, inaccurate, not current, or incomplete, or if zero5 has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, zero5 reserves the right, in its sole and absolute discretion, to immediately suspend or terminate your account and refuse any and all current or future use of the Websites.
        </Text>
        <Heading as="h3">5.	Intellectual Property Rights</Heading>
        <Text>
          The Websites and their entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by zero5, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.
        </Text>
        <Text>
          If you are an End User, these Terms of Use permit you to use the Websites solely for your personal, non-commercial use only. If you are accessing and using the Websites on behalf of a Parking Facility Operator, these Terms of Use permit you to use the Websites solely for the Parking Facility Operator’s internal business use. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Websites, except as follows:
        </Text>
        <List>
          <ListItem>
            Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
          </ListItem>
          <ListItem>
            You may store files that are automatically cached by your Web browser for display enhancement purposes.
          </ListItem>
          <ListItem>
            If you are using the Websites as an End User, you may print one copy of a reasonable number of pages of the Websites for your own personal, non-commercial use and not for further reproduction, publication, or distribution.
          </ListItem>
          <ListItem>
            If you are using the Websites as a Parking Facility Operator, you may print copies of a reasonable number of pages of the Websites for your company’s internal business use and not for further reproduction, publication, or distribution
          </ListItem>
        </List>
        <Text>
          You must not:
        </Text>
        <List>
          <ListItem>
            Modify copies of any materials from this site.
          </ListItem>
          <ListItem>
            Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.
          </ListItem>
          <ListItem>
            Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.
          </ListItem>
        </List>
        <Text>
          If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Websites in breach of the Terms of Use, your right to use the Websites will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Websites or any content on the Websites is transferred to you, and all rights not expressly granted are reserved by zero5. Any use of the Websites not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.
        </Text>
        <Heading as="h3">6.	Trademarks</Heading>
        <Text>
          The zero5 name, our logo, and all related names, logos, product and service names, designs and slogans are trademarks of zero5 or its licensors. You must not use such marks without the prior written permission of zero5.
        </Text>
        <Heading as="h3">7.	Prohibited Uses</Heading>
        <Text>
          You may use the Websites only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Websites: (a) in any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries); or (b) to engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Websites, or which, as determined by us, may harm zero5 or users of the Websites or expose them to liability.
        </Text>
        <Text>
          Additionally, you agree not to:
        </Text>
        <List>
          <ListItem>
            Use the Websites in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Websites, including their ability to engage in real time activities through the Websites.
          </ListItem>
          <ListItem>
            Use any robot, spider or other automatic device, process or means to access the Websites for any purpose, including monitoring or copying any of the material on the Websites.
          </ListItem>
          <ListItem>
            Use any manual process to monitor or copy any of the material on the Websites or for any other unauthorized purpose without our prior written consent.
          </ListItem>
          <ListItem>
            Use any device, software or routine that interferes with the proper working of the Websites.
          </ListItem>
          <ListItem>
            Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.
          </ListItem>
          <ListItem>
            Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Websites, the server on which the Websites are stored, or any server, computer or database connected to the Websites.
          </ListItem>
          <ListItem>
            Attack the Websites via a denial-of-service attack or a distributed denial-of-service attack.
          </ListItem>
          <ListItem>
            Otherwise attempt to interfere with the proper working of the Websites.
          </ListItem>
        </List>
        <Heading as="h3">8.	License to Parking Facility Operator Data</Heading>
        <Text>
          Subject to and conditioned on the Parking Facility Operator’s compliance with all terms and conditions of the services agreement between zero5 and that Parking Facility Operator, zero5 hereby grants you a non-exclusive, non-sublicensable, and non-transferable license to use the data (including personal data) available to the Parking Facility Operator on or through the websites solely for the Parking Facility Operators use in operating and managing the parking facility. You may not use any such information for any unlawful purpose, including, but not limited to, for the purpose of exploiting, harming, or attempting to exploit or harm any End User or other person.
        </Text>

        <Heading as="h3">9.	Monitoring and Enforcement; Termination</Heading>
        <Text>
          We have the right to: (a) take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Websites; or (b) terminate or suspend your access to all or part of the Websites for any or no reason, including without limitation, for any violation of these Terms of Use.
        </Text>
        <Text>
          Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Websites. YOU WAIVE AND HOLD HARMLESS ZERO5 AND ITS LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </Text>
        <Text>
          We assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.
        </Text>

        <Heading as="h3">10.	Reliance on Information Posted</Heading>
        <Text>
          The information presented on or through the Websites is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Websites, or by anyone who may be informed of any of its contents.
        </Text>
        <Text>
          These Websites may include content provided by third parties, including materials provided by other users and third-party licensors. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by zero5, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of zero5. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.
        </Text>

        <Heading as="h3">11.	Changes to the Websites</Heading>
        <Text>
          We may update the content on the Websites from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Websites may be out of date at any given time, and we are under no obligation to update such material.
        </Text>

        <Heading as="h3">12.	Information About You and Your Visits to the Websites</Heading>
        <Text>
          All information we collect on the Websites is subject to our Privacy Policy. By using the Websites, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.
        </Text>

        <Heading as="h3">13.	Credit Card Processing and Contracts Between You and a Parking Facility Operator</Heading>
        <Text>
          If you are an End User, our Websites allow you to enter payment information to pay for services provided by Parking Facility Operators. We accept all major credit and debit cards for all purchases. You represent and warrant that (i) the credit card information or debit card information that you supply to us is true, correct and complete, (ii) you are duly authorized to use such credit card or debit card for the purchase, (iii) charges incurred by you will be honored by your credit card company or debit card company, (iv) you will pay charges incurred by you at the posted prices and all applicable taxes, if any, regardless of the amount quoted on the respective Website at the time of your order, and (v) if you are a minor, you are authorized by your parent or guardian to use the credit card or debit card for the purchase. When you provide us your credit or debit card information, we do not receive your credit or debit card number. Instead, our payment processor stores your credit or debit card information, and we may only store a “token” representation of your credit or debit card that may only be used by us in order to process your payments to Parking Facility Operators.
        </Text>
        <Text>
          If you are an End User, our Websites contain various parking service offerings that allow you to enter into online transactions with Parking Facility Operators. Each of these Parking Facility Operators may have their own terms and conditions for their parking services. You acknowledge and agree that zero5 is not a party or an agent of any transactions conducted with third parties via our Websites. We do not set, control, or endorse the price, contract terms, quality, safety, conformance, or legality of the services provided or offered on or through our Websites. As between zero5 and you, you take sole responsibility for any claims regarding your use of these services. You further agree and acknowledge that the applicable Parking Facility Operator takes sole responsibility for all services made available by them on or through the Websites. In no event shall zero5 be responsible for any liability resulting from or relating to such services or for your use or inability to use such services offered by Parking Facility Operators on or through the Website.
        </Text>

        <Heading as="h3">14.	Linking to the Websites</Heading>
        <Text>
          You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval, or endorsement on our part without our express consent.
        </Text>

        <Heading as="h3">15.	Geographic Restrictions</Heading>
        <Text>
          The owner of the Websites is based in the state of California in the United States. We provide the Websites for use only by persons located in the United States. We make no claims that the Websites or any of its content is accessible or appropriate outside of the United States. Access to the Websites may not be legal by certain persons or in certain countries. If you access the Websites from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.
        </Text>

        <Heading as="h3">16.	Disclaimer of Warranties</Heading>
        <Text>
          You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Websites will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITES LINKED TO THEM.
        </Text>
        <Text>  
          YOUR USE OF THE WEBSITES, THEIR CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES IS AT YOUR OWN RISK. THE WEBSITES, THEIR CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER ZERO5 NOR ANY PERSON ASSOCIATED WITH ZERO5 MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITES. WITHOUT LIMITING THE FOREGOING, NEITHER ZERO5 NOR ANYONE ASSOCIATED WITH ZERO5 REPRESENTS OR WARRANTS THAT THE WEBSITES, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </Text>
        <Text>
          ZERO5 HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.
        </Text>
        <Text>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </Text>

        <Heading as="h3">17.	Limitation on Liability</Heading>
        <Text>
          IN NO EVENT WILL ZERO5, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITES, ANY WEBSITES LINKED TO THEM, ANY CONTENT ON THE WEBSITES OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITES OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
        </Text>
        <Text>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </Text>

        <Heading as="h3">18.	Indemnification</Heading>
        <Text>
          You agree to defend, indemnify and hold harmless zero5, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Websites, including, but not limited to any use of the Websites’ content, services and products other than as expressly authorized in these Terms of Use or your use of any information obtained from the Websites.
        </Text>

        <Heading as="h3">19.	Governing Law and Jurisdiction</Heading>
        <Text>
          All matters relating to the Websites and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of California without giving effect to any choice or conflict of law provision or rule (whether of the State of California or any other jurisdiction).
        </Text>
        <Text>
          Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Websites shall be instituted exclusively in the federal courts of the United States located in the City and/or County of San Francisco or, if the federal courts do not have jurisdiction, the courts of the State of California located in the City and/or County of San Mateo, although we retain the right to bring any suit, action or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
        </Text>

        <Heading as="h3">20.	Limitation on Time to File Claims</Heading>
        <Text>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITES MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
        </Text>

        <Heading as="h3">21.	Waiver and Severability</Heading>
        <Text>
          No waiver by zero5 of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of zero5 to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.
        </Text>
        <Text>
          If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.
        </Text>

        <Heading as="h3">22.	Entire Agreement</Heading>
        <Text>
          The Terms of Use and our Privacy Policy constitute the sole and entire agreement between you and zero5 with respect to the Websites and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Websites.
        </Text>

        <Heading as="h3">23.	Your Comments and Concerns</Heading>
        <Text>
          The Websites are operated by zero5 at 3 E. 3rd Ave. Suite 221 San Mateo, CA 94401 USA.
        </Text>
        <Text>
          All other feedback, comments, requests for technical support and other communications relating to the Websites should be directed to:
          {' '}
          <StyledLink href="mailto:team@zero5.co">team@zero5.co</StyledLink>.
        </Text>
        <Heading as ="h3">24.	Phone Subscription Terms and Conditions</Heading>
        <Text>
         When you opt in to the service, You are subscribed to Zero5 alerts. Msg & data rates may apply.
        </Text>
        <Text>
        You can cancel the SMS or MMS service at any time by texting 'STOP' to phone number. When you send the message 'STOP' to us, you will receive a confirmation SMS that you have been unsubscribed. After this, you will no longer receive any SMS or MMS messages from us. If you wish to re-subscribe, simply sign in and accept the subscription as you did before, and we will resume sending you SMS and MMS messages        </Text>
        <Text>
        You can get more information at any time by texting "HELP" to phone number. When you send the SMS message "HELP" to us, we respond with instructions on how to use our service and how to unsubscribe.
        </Text>
        <Text>
        We are able to deliver messages to the following mobile phone carriers: Major carriers: AT&T, Verizon Wireless, Sprint, T-Mobile, MetroPCS, US Cellular, Alltel, Boost Mobile, Nextel, and Virgin Mobile. Minor carriers: Alaska Communications Systems (ACS), Appalachian Wireless (EKN), Bluegrass Cellular, Cellular One of East Central IL (ECIT), Cellular One of Northeast Pennsylvania, Cincinnati Bell Wireless, Cricket, Coral Wireless (Mobi PCS), COX, Cross, Element Mobile (Flat Wireless), Epic Touch (Elkhart Telephone), GCI, Golden State, Hawkeye (Chat Mobility), Hawkeye (NW Missouri), Illinois Valley Cellular, Inland Cellular, iWireless (Iowa Wireless), Keystone Wireless (Immix Wireless/PC Man), Mosaic (Consolidated or CTC Telecom), Nex-Tech Wireless, NTelos, Panhandle Communications, Pioneer, Plateau (Texas RSA 3 Ltd), Revol, RINA, Simmetry (TMP Corporation), Thumb Cellular, Union Wireless, United Wireless, Viaero Wireless, and West Central (WCC or 5 Star Wireless). Carriers are not liable for delayed or undelivered messages.
        </Text>
        <Text>
        Message and data rates may apply for any messages that we send to you or you send to us. Contact your wireless provider for more information about your text plan or data plan. If you have questions about the services provided by this short code, email us at support@zero5.co.
        </Text>
        <Text>
        If you have any questions regarding privacy, please read our privacy policy: <StyledLink href="/policies/privacy-policy">Privacy Policy</StyledLink>
        </Text>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  padding: 20px;
  font-weight: 300;
`;

const MainHeading = styled.h1`
  margin: 0 0 30px 0;
  text-align: center;
  font-size: 1.846rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.167;
`;

const Text = styled.p`
  margin: 0 0 10px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;

  &:last-child {
    margin: 0;
  }
`;

const StyledLink = styled.a`
  color: #386344;
  font-size: 12px;
`;

const Heading = styled.h2<{ inline?: boolean; }>`
  display: ${({ inline }) => (inline ? 'inline' : 'block')};
  margin: 0 10px 10px 0;
  font-size: 16px;
  font-weight: 500;
`;

const List = styled.ul`
  margin: 0 0 10px 0;
  padding: 0 0 0 15px;
  list-style: disc;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;

  &:last-child {
    margin: 0;
  }
`;

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export default TermsOfUse;
