import { useMemo } from 'react';

import Spacer from 'components/Spacer/Spacer';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import {heightMobileBreakpoints, viewportBreakpoints} from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import HomePageSlider from '../HomePageSlider';
import { S } from './Styles';
import {ORIENTATION} from 'utils/enums';

const HomePageWelcome = () => {
  const { width, height, orientation } = useViewport();

  const isMobile = useMemo(
    () => (width < parseInt(viewportBreakpoints.tablet) || (height < heightMobileBreakpoints && orientation === ORIENTATION.landscape)),
    [width, orientation, height]
  );

  return (
    <S.Container>
      <S.LeftSection>
        <S.TextBlock>
          <div style={{ marginLeft: '-2px' }}>
            <Typography
              fontSize={isMobile ? 'fz24' : 'fz48'}
              fontWeight='500'
              lineHeight={isMobile ? '26' : '69'}
              text='Welcome to'
            />
          </div>
          <Spacer height={25} />
          <S.SpanText
            fontSize={isMobile ? 'fz14' : 'fz18'}
            lineHeight={isMobile ? '18' : '26'}
            fontWeight='300'
            text='Your future complete automated '
          />
          <S.SpanText
            fontSize={isMobile ? 'fz14' : 'fz18'}
            lineHeight={isMobile ? '7' : '26'}
            fontWeight={'400'}
            text='parking garage management'
            fontColor={theme.colors.LightGreen}
          />
          &nbsp;
          <S.SpanText
            fontSize={isMobile ? 'fz14' : 'fz18'}
            lineHeight={isMobile ? '18' : '26'}
            fontWeight='300'
            text='solution'
          />
          <Spacer height={isMobile ? 23 : 50} />
          <S.Btn
            skew
            arrow
            title='Learn More'
            onClick={() => {}}
          />
        </S.TextBlock>
      </S.LeftSection>
      <S.SliderContainer>
        <HomePageSlider />
      </S.SliderContainer>
    </S.Container>
  );
};

export default HomePageWelcome;
