import theme from 'theme/theme';

import { IconsType } from './icon.types';

const AiIcon = ({
  width = 34,
  height = 34,
  color = theme.colors.White,
  ...props
}: IconsType) => {
  return (
    <svg
      fill='none'
      width={width}
      height={height}
      viewBox='0 0 34 34'
      {...props}
    >
      <path stroke={color} d='M1.273.5H33.5v32.227H1.273z' />
      <path
        d='M16.693 21.001v-4.535c0-.34-.062-.633-.185-.88a1.293 1.293 0 0 0-.562-.57c-.246-.135-.557-.203-.932-.203a2.11 2.11 0 0 0-.896.176 1.448 1.448 0 0 0-.59.475c-.14.2-.21.425-.21.677h-2.11c0-.375.091-.739.273-1.09.181-.352.445-.665.79-.94a3.95 3.95 0 0 1 1.24-.651 5.155 5.155 0 0 1 1.617-.237c.715 0 1.348.12 1.899.36.556.24.993.604 1.31 1.09.322.48.483 1.084.483 1.81v4.228c0 .434.029.823.088 1.169.064.34.155.636.272.888v.14h-2.17a3.753 3.753 0 0 1-.238-.87 7.11 7.11 0 0 1-.08-1.037ZM17 17.125l.018 1.31h-1.52c-.393 0-.739.038-1.038.114-.299.07-.548.176-.747.316-.199.14-.348.31-.448.51-.1.2-.15.425-.15.677 0 .252.06.483.176.694.118.205.287.366.51.484.229.117.504.175.826.175.434 0 .812-.088 1.134-.263.328-.182.586-.402.774-.66.187-.263.287-.512.298-.747l.686.94c-.07.241-.19.499-.36.774-.17.276-.393.54-.668.791-.27.246-.595.449-.976.607-.375.158-.809.237-1.3.237-.622 0-1.176-.123-1.662-.37a2.953 2.953 0 0 1-1.143-1.01 2.62 2.62 0 0 1-.413-1.45c0-.504.094-.95.282-1.336.193-.393.474-.72.843-.984.375-.264.832-.463 1.371-.598.54-.14 1.155-.211 1.846-.211H17Zm6.302-7.717v13.5h-2.127v-13.5h2.127Z'
        fill={color}
      />
    </svg>
  );
};

export default AiIcon;
