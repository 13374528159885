import { ScrollTrigger } from 'gsap/ScrollTrigger';

export const getRem = (size: number) => {
  return `calc(${size / 16} * 1rem)`;
};

export function debounce(
  func: (...args: any[]) => void,
  timeout: number
): (...args: any[]) => void {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any[]): void => {
    const next = () => func(...args);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(next, timeout > 0 ? timeout : 300);
  };
}

export function getAbsoluteHeight(element: HTMLElement | string): number {
  const el: any =
    typeof element === 'string' ? document.querySelector(element) : element;

  const styles = window.getComputedStyle(el);
  const margin =
    parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom']);

  return Math.ceil(el.offsetHeight + margin);
}

export function isLandscape(): boolean {
  return window.matchMedia('(orientation:landscape)').matches;
}

export const clearTriggerById = (key: string): void => {
  ScrollTrigger.getAll().forEach((item: ScrollTrigger) => {
    if (item.vars === key) {
      item.kill();
    }
  });
};
