import * as React from 'react'

import { IconsType } from './icon.types';

const  KeyIcon = (props: IconsType) => (
  <svg
    width={20}
    height={11}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.543 3.75C9.75 1.396 7.418-.188 4.809.355 2.901.757 1.351 2.359.951 4.362.268 7.75 2.718 10.75 5.834 10.75c2.175 0 4.025-1.461 4.709-3.5h3.625V9c0 .962.75 1.75 1.667 1.75.916 0 1.666-.788 1.666-1.75V7.25c.917 0 1.667-.788 1.667-1.75 0-.963-.75-1.75-1.667-1.75h-6.958Zm-4.709 3.5c-.916 0-1.666-.788-1.666-1.75 0-.963.75-1.75 1.666-1.75.917 0 1.667.787 1.667 1.75 0 .962-.75 1.75-1.667 1.75Z'
      fill='#386344'
    />
  </svg>
)

export default KeyIcon;
