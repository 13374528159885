import styled from 'styled-components';

import Button from 'components/Button/Button';
import theme from 'theme/theme';
import { getRem } from 'utils/functions';
import PageLabel from 'layout/PageLabel/PageLabel';

const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  margin-bottom: 35px;
  justify-content: space-evenly;
  overflow-y: hidden;
  position: relative;
  background-color: ${(props) => props.theme.colors.White};

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    height: auto;
    flex-direction: column;
  }
`;

const Label = styled(PageLabel)`
  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    margin-top: ${getRem(89)};
    align-self: flex-center;
  }
`;

const Image = styled.img`
  left: 0;
  width: ${getRem(885)};
  top: 310px;
  position: absolute;

  @media ${theme.deviceSizes.desktopM} {
    max-width: ${getRem(780)};
  }

  @media ${theme.deviceSizes.desktopS} {
    max-width: ${getRem(700)};
  }

  @media ${theme.deviceSizes.laptopL} {
    max-width: ${getRem(550)};
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    max-width: ${getRem(470)};
    left: ${getRem(-50)};
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    width: 100%;
    position: relative;
    align-self: flex-start;
    top: ${getRem(20)};
  }

  /* @media ${theme.deviceSizes.laptopLandScape} {
    top: ${getRem(150)};
    max-width:${getRem(400)};
  } */
`;

const TextContent = styled.div`
  @media ${theme.deviceSizes.tablet} {
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-basis: 38%;
  height: auto;
  max-width: ${getRem(557)};
  margin-left: auto;
  margin-right: 14%;
  position: relative;
  flex-direction: column;
  margin-top: ${getRem(391)};

  @media (max-width: 3008px) {
    margin-right: 24.5%;
  }

  @media (max-width: 2340px) {
    margin-right: 19.4%;
  }

  @media (max-width: 2056px) {
    margin-right: 16%;
  }

  @media (max-width: 1920px) {
    margin-right: 14%;
  }

  @media ${theme.deviceSizes.desktop1800} {
    margin-right: 10%;
  }

  @media ${theme.deviceSizes.desktopM} {
    max-width: ${getRem(470)};
  }

  @media ${theme.deviceSizes.desktopS} {
    margin-top: ${getRem(350)};
    margin-right: 7%;
  }

  @media ${theme.deviceSizes.laptopL} {
    margin-top: ${getRem(300)};
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    margin-top: ${getRem(200)};
    max-width: ${getRem(400)};
    margin-right: 5%;
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    height: auto;
    width: ${getRem(222)};
    margin-top: ${getRem(35)};
    margin-left: ${getRem(77)};
    margin-right: none;
  }

  /* @media ${theme.deviceSizes.laptopLandScape} {
    margin-top: ${getRem(90)};
    margin-right: ${getRem(20)};
    flex-basis: 46%;
  } */
`;

const LearnMoreButton = styled(Button).attrs({
  skew: true,
  arrow: true,
  title: 'Learn More',
  themeColor: theme.colors.Yellow,
})`
  flex-shrink: 0;
  margin-top: ${getRem(200)};
  margin-bottom: ${getRem(141)};
  padding: 0 15px 0 40px;
  justify-content: space-between;
  align-self: flex-start;

  &:hover {
    &::before {
      background-color: ${(props) => props.theme.colors.MainGreen};
    }
  }

  @media ${theme.deviceSizes.desktopM} {
    margin-top: ${getRem(110)};
    margin-bottom: ${getRem(100)};
  }

  @media ${theme.deviceSizes.desktopS} {
    margin-top: 20%;
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    margin-top: ${getRem(50)};
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    margin-top: initial;
    margin-bottom: ${getRem(35)};
  }

  /* @media ${theme.deviceSizes.laptopLandScape} {
    margin-top: ${getRem(35)};
    margin-bottom: ${getRem(35)};
  } */
`;

export const S = {
  Image,
  Label,
  Container,
  RightSection,
  LearnMoreButton,
  TextContent,
};
