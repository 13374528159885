import { FC, useMemo, useState } from 'react';

import { privateRoutes } from 'app/Routes';
import { RouteTypes } from 'app/types';
import parkingGarage from 'components/assets/images/parkingGarageFooter.png';
import AiIcon from 'components/icons/Ai.icon';
import LinkedInIcon from 'components/icons/LinkedIn.icon';
import ZeroSlashed from 'components/icons/ZeroSlashed.icon';
import { angelList, linkedin, viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import { SFooter } from './SFooter';

const currentYear = new Date().getFullYear();

const Footer: FC = () => {
  const [isClicked, setIsClick] = useState<boolean>(false);

  const navLinks = useMemo(() => {
    return privateRoutes.filter((el) => el.route !== RouteTypes.default);
  }, []);

  const { width } = useViewport();
  const isMobile = useMemo(
    () => width <= parseInt(viewportBreakpoints.tablet),
    [width]
  );

  return (
    <SFooter.MainContainer className='footer'>
      <SFooter.UpperArea>
        <SFooter.PresentationArea>
          <div>
            <SFooter.Logo>
              <ZeroSlashed />
            </SFooter.Logo>
          </div>
          <SFooter.PresentationText>
            Say hello, <span>get more info</span> or schedule a demo
            {/* {isMobile ? (
              <>
                Say hello, <span>get more info</span> or schedule a demo
              </>
            ) : (
              <>
                Say Hello, <span>Get More Info</span> Or Schedule A Demo
              </>
            )} */}
          </SFooter.PresentationText>
          <SFooter.ImageContainer>
            <SFooter.Image src={parkingGarage} alt='parking garage' />
          </SFooter.ImageContainer>
          <SFooter.EmailContainer>
            <h4
              style={{
                fontWeight: '300',
                fontSize: '18px',
                lineHeight: '26px',
              }}
            >
              team@zero5.co
            </h4>
          </SFooter.EmailContainer>
        </SFooter.PresentationArea>
        <SFooter.Form>
          <SFooter.FormInput placeholder='Name'></SFooter.FormInput>
          <SFooter.FormInput placeholder='Email'></SFooter.FormInput>
          <SFooter.FormInput placeholder='Purpose'></SFooter.FormInput>
          <SFooter.TextInput placeholder='Message'></SFooter.TextInput>
          <div>
            <SFooter.SubmitButton
              style={{
                backgroundColor: isClicked ? 'white' : '',
                color: isClicked ? 'black' : '',
              }}
              width={124}
              height={54}
              onClick={() => {
                setIsClick(true);
              }}
            >
              Send
            </SFooter.SubmitButton>
          </div>
        </SFooter.Form>
      </SFooter.UpperArea>
      <SFooter.LowerArea>
        <SFooter.HL />
        <SFooter.Footer>
          <SFooter.SocialBlock>
            <div>
              <SFooter.Social>
                <a href={linkedin} target="_blank" rel="noreferrer">
                  <LinkedInIcon width='33.23' height='33.23' />
                </a>
              </SFooter.Social>
            </div>
            <div>
              <SFooter.Social>
              <a href={angelList} target="_blank" rel="noreferrer">
                  <AiIcon width='33.23' height='33.23' />
                </a>
              </SFooter.Social>
            </div>
          </SFooter.SocialBlock>
          <SFooter.NavSection style={{ height: '104px' }}>
            {navLinks.map((el, i) => {
              return (
                <li key={i}>
                  {width > 768 && (
                    <SFooter.NavLink to={el.route}>{el.name}</SFooter.NavLink>
                  )}
                </li>
              );
            })}
          </SFooter.NavSection>

          <SFooter.PropertyBlock>
            <p>
              <SFooter.FooterLink to='/policies/privacy-policy'>Privacy statement</SFooter.FooterLink>
            </p>
            <p>
              <SFooter.FooterLink to='/policies/terms-of-use'>Terms of use</SFooter.FooterLink>
            </p>
            <p>Copyright {currentYear}. zero5, Inc</p>
            <p>All Rights Reserved</p>
          </SFooter.PropertyBlock>
        </SFooter.Footer>
      </SFooter.LowerArea>
    </SFooter.MainContainer>
  );
};

export default Footer;
