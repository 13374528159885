import styled from 'styled-components';

import Button from 'components/Button/Button';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Container = styled.div`
  display: flex;
  height: 100vh;
  align-content: center;
  justify-content: space-between;

  @media ${theme.deviceSizes.tablet} {
    display: flex;
    flex-direction: column;
    margin-bottom: ${getRem(51)};
    align-content: initial;
    justify-content: initial;
  }
`;

const SpanText = styled(Typography)`
  display: inline;
`;

const LeftSection = styled.div`
  display: flex;
  flex-basis: 38%;
  align-items: flex-end;
  justify-content: flex-start;
  padding-left: ${(props) => props.theme.spacers.primaryLeft};

  @media ${theme.deviceSizes.laptopL} {
    padding-left: 5%;
  }

  @media ${theme.deviceSizes.tablet} {
    order: 1;
    padding-left: 0;
    align-items: start;
    padding-left: ${getRem(77)};
    align-items: start;
  }

  @media ${theme.deviceSizes.laptopLandScape} { {
    padding-left: ${getRem(20)};
  }
`;

const Btn = styled(Button)`
  justify-content: space-between;
  padding: 0 ${getRem(10)} 0 ${getRem(60)};

  @media ${theme.deviceSizes.tablet} {
    padding: 0 ${getRem(10)} 0 ${getRem(30)};
  }
`;

const SliderContainer = styled.div`
  flex-basis: 62%;
  overflow: hidden;
  position: relative;

  @media ${theme.deviceSizes.tablet} {
    order: 0;
    height: auto;
    flex-basis: auto;
  }
`;

const TextBlock = styled.div`
  padding-bottom: ${getRem(144)};
  width: ${getRem(427)};

  @media ${theme.deviceSizes.laptop} {
    margin-top: ${getRem(32)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    padding-bottom: ${getRem(90)};
  }

  @media ${theme.deviceSizes.tablet} {
    width: ${getRem(280)};
    padding: 0;
    margin-bottom: ${getRem(23)};
    margin-top: ${getRem(32)};
  }
`;

export const S = {
  Btn,
  LeftSection,
  SliderContainer,
  Container,
  TextBlock,
  SpanText,
};
