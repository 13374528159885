import { Link } from 'react-router-dom';
import styled from 'styled-components';

import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const MainContainer = styled.footer`
  position: relative;
  height: ${getRem(1083)};
  background-color: ${(props) => props.theme.colors.Black};
  overflow: hidden;

  @media ${theme.deviceSizes.tablet} {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: flex-center;
    justify-content: flex-start;
    padding: 0 ${getRem(20)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    padding: 0 ${getRem(20)};
  }
`;

const UpperArea = styled.div`
  display: grid;
  height: ${getRem(840)};
  grid-template-columns: 1fr 1fr;

  @media ${theme.deviceSizes.tablet} {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`;

const PresentationArea = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: ${(props) => props.theme.spacers.primaryLeft};

  @media ${theme.deviceSizes.laptopL} {
    padding-left: ${getRem(42)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    padding-left: 0;
  }

  & > h3 {
    z-index: 3;
    width: ${getRem(401)};
    margin: ${getRem(143)} 0 ${getRem(81)} 0;
    color: ${(props) => props.theme.colors.White};
    font-size: ${(props) => props.theme.fontSizes.fz28};

    @media ${theme.deviceSizes.tablet} {
      margin: ${getRem(55)} 0 ${getRem(53)} 0;
      width: ${getRem(222)};
      font-size: ${(props) => props.theme.fontSizes.fz24};
    }
  }

  & > h3 span {
    color: ${(props) => props.theme.colors.LightGreen};
  }

  & > h4 {
    color: ${(props) => props.theme.colors.White};
    font-size: ${(props) => props.theme.fontSizes.fz18};
    font-weight: 300;

    @media ${theme.deviceSizes.tablet} {
      display: none;
    }
  }
`;

const PresentationText = styled.h3`
  margin-left: 0px;
  line-height: 34.5px;
  font-weight: 500;
  font-size: 28px;

  @media ${theme.deviceSizes.laptop} {
    margin-left: ${getRem(-2)};
  }

  @media ${theme.deviceSizes.tablet} {
    margin-left: ${getRem(25)};
    line-height: 26px;
    font-size: 24px;
  }


`;

const Logo = styled.div`
  position: absolute;
  top: ${getRem(110)};
  width: ${getRem(115)};
  height: ${getRem(117)};

  @media ${theme.deviceSizes.laptopL} {
    left: ${getRem(39)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    left: ${getRem(-2)};
  }

  @media ${theme.deviceSizes.tablet} {
    top: ${getRem(30)};
    left: ${getRem(60)};
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: ${getRem(407)};
  margin-bottom: ${getRem(47)};

  @media ${theme.deviceSizes.tablet} {
    display: none;
  }
`;

const Image = styled.img`

  @media ${theme.deviceSizes.desktop1800} {
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: ${getRem(145)} 25% 0 14%;

  @media ${theme.deviceSizes.desktop1800} {
    padding: ${getRem(145)} 25% 0 25%;
  }

  @media ${theme.deviceSizes.laptopL} {
    padding: ${getRem(145)} 10% 0 10%;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    padding-right: 0;
  }

  @media ${theme.deviceSizes.tablet} {
    padding: 0;
    align-items: flex-end;
    & > * {
      margin-bottom: ${getRem(16)}!important;

      &:last-child {
        margin-bottom: 0!important;
      }
    }
  }
`;

const SubmitButton = styled.button<{ width?: number; height?: number }>`
  font-family: Roboto, san-serif;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: inherit;
  color: ${(props) => props.theme.colors.White};
  font-size: ${(props) => props.theme.fontSizes.fz24};
  font-weight: 300 !important;
  border: ${getRem(1)} solid #fff;

  &:hover {
    color: ${theme.colors.LightGreen};
  }

  &:active {
    transition: color 0s;
    color: ${theme.colors.White};
    background: ${theme.colors.Black};
  }

  @media ${theme.deviceSizes.tablet} {
    width: ${getRem(107)};
    height: ${getRem(46)};
    align-self: flex-end;
  }
`;

const FormInput = styled.input`
  width: ${getRem(543)};
  height: ${getRem(51)};
  margin-bottom: ${getRem(30)};
  text-indent: ${getRem(24.5)};
  font-size: ${(props) => props.theme.fontSizes.fz18};
  border: 1px solid #ffffff;
  border-radius: 0;
  font-weight: 300 !important;

  @media ${theme.deviceSizes.desktopM} {
    width: 100%;
  }

  &::placeholder {
    opacity: 0.4;
    font-family: Roboto, sans-serif !important;
    color: ${(props) => props.theme.colors.Black};
    font-size: ${(props) => props.theme.fontSizes.fz18};
    font-weight: 300 !important;
  }

  @media ${theme.deviceSizes.tablet} {
    margin-bottom: 0;
    height: ${getRem(37)};
    text-indent: ${getRem(16)};

    &::placeholder {
      font-size: ${(props) => props.theme.fontSizes.fz14};
      font-weight: 300 !important;
    }
  }
`;

const TextInput = styled.textarea`
  width: ${getRem(543)};
  height: ${getRem(322)};
  padding-top: ${getRem(21)};
  margin-bottom: ${getRem(24)};
  text-indent: ${getRem(24.5)};
  font-family: 'Roboto', sans-serif;
  color: ${(props) => props.theme.colors.Black};
  font-size: ${(props) => props.theme.fontSizes.fz18};
  border: 1px solid #ffffff;
  border-radius: 0;
  font-weight: 300 !important;
  resize: none;

  @media ${theme.deviceSizes.desktopM} {
    width: 100%;
  }

  &::placeholder {
    opacity: 0.4;
    color: ${(props) => props.theme.colors.Black};
    font-size: ${(props) => props.theme.fontSizes.fz18};
    font-weight: 300 !important;
  }

  @media ${theme.deviceSizes.tablet} {
    text-indent: ${getRem(16)};
    &::placeholder {
      font-size: ${(props) => props.theme.fontSizes.fz14};
    }
  }
`;

const LowerArea = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
`;

const Footer = styled.footer`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2.9fr 2fr;
  padding-top: ${getRem(26)};

  @media (max-width: 3008px) {
    grid-template-columns: 1fr 1.5fr 1fr;
  }

  @media (max-width: 2340px) {
    grid-template-columns: 1fr 2.1fr 1fr;
  }

  @media (max-width: 2056px) {
    grid-template-columns: 1fr 2.6fr 1fr;
  }

  @media (max-width: 1920px) {
    grid-template-columns: 1fr 2.9fr 1fr;
  }



  @media ${theme.deviceSizes.laptopLandScape} {
    grid-template-columns: 1.4fr 5.5fr 2.3fr;
  }

  @media ${theme.deviceSizes.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

const SocialBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: ${getRem(100)};
  height: ${getRem(104)};
  padding-left: ${(props) => props.theme.spacers.primaryLeft};
  margin-top: ${getRem(10)};

  & > * {
    margin-right: ${getRem(20)};

    &:last-child {
      margin-right: 0;
    }
  }

  @media ${theme.deviceSizes.laptopL} {
    margin-left: ${getRem(-139)};
  }

  @media ${theme.deviceSizes.tablet} {
    margin-left: initial;
    padding-left: 0;
    width: auto;

    & > * {
      margin-right: ${getRem(26)};

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    margin-left: ${getRem(-181)};
  }

  @media not all and (min-resolution: 0.001dpcm) {
    @media {
      width: 70px;
    }
  }
`;

const HL = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.White};
  margin-top: ${getRem(48)};

  @media ${theme.deviceSizes.tablet} {
    margin-top: ${getRem(28)};
  }
`;

const Social = styled.div`
  width: ${getRem(33)};
`;

const NavSection = styled.ul`
  display: flex;
  overflow-y: hidden;
  justify-content: flex-end;
  height: 104px;
  margin-top: ${getRem(-12)};

  & > li {
    margin-right: ${getRem(20)};
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    justify-content: initial;

    & > li {
      margin-right: ${getRem(5)};
    }
  }

  @media ${theme.deviceSizes.tablet} {
    display: none;
  }
`;

const NavLink = styled(Link)`
  font-weight: 300;
  text-decoration: none;
  padding-right: ${getRem(15)};
  color: ${(props) => props.theme.colors.White};
  font-size: ${(props) => props.theme.fontSizes.fz18};
  line-height: 63.5px;

  @media ${theme.deviceSizes.laptopLandScape} {
    padding: 0 ${getRem(6)} 0 ${getRem(6)};
  }
`;

const PropertyBlock = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${getRem(185)};
  width: 100%;
  height: ${getRem(243)};

  @media ${theme.deviceSizes.desktop1800} {
    padding-left: 10%;
  }

  & > p {
    color: ${(props) => props.theme.colors.White};
    font-size: ${theme.fontSizes.fz14};
    line-height: 37.5px;
  }

  @media ${theme.deviceSizes.laptopL} {
    padding-right: ${getRem(20)};
  }

    @media ${theme.deviceSizes.tablet} {
    padding-bottom: 7px;
    padding-right: initial;
    height: ${getRem(164)};
    width: max-content;
  }
`;

const FooterLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.White};
  &:hover {
    text-decoration: underline;
  }
`;

const EmailContainer = styled.div`
  padding-top: ${getRem(15)};
  color: ${theme.colors.White};

  @media ${theme.deviceSizes.tablet} {
    display: none;
  }
`;

export const SFooter = {
  MainContainer,
  UpperArea,
  Logo,
  ImageContainer,
  Image,
  Form,
  FormInput,
  TextInput,
  PresentationArea,
  PresentationText,
  LowerArea,
  Footer,
  SocialBlock,
  HL,
  Social,
  NavSection,
  NavLink,
  PropertyBlock,
  SubmitButton,
  FooterLink,
  EmailContainer,
};
