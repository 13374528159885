import theme from 'theme/theme';

import { IconsType } from './icon.types';

const ArrowButton = ({
  width = 16,
  height = 26,
  color = theme.colors.White,
  ...props
}: IconsType) => (
  <svg fill='none' width={width} height={height} viewBox='0 0 16 26' {...props}>
    <path
      d='M5.979 25.94H0l9.424-12.97L0-.002H5.98l9.423 12.97L5.98 25.94Z'
      fill={color}
    />
  </svg>
);

export default ArrowButton;
