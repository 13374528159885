import { viewportBreakpoints } from 'utils/constants';
import { getRem } from 'utils/functions';
import {ORIENTATION} from 'utils/enums';

const theme = {
  colors: {
    Black: '#262626',
    White: '#FFFFFF',
    MainGreen: '#386344',
    OceanBlue: '#2541B2',
    WhiteGreen: '#D9DFD6',
    LightGreen: '#A0DCAA',
    Orange: '#E18558',
    Blue: '#1768AC',
    Purple: '#D0D0E8',
    Pink: '#EEC5D5',
    Yellow: '#E8CC5F',
  },
  fontWeight: {
    regular: 400,
    bold: 900,
  },
  spacers: {
    primaryLeft: getRem(181.091),
  },
  fontSizes: {
    fz10: getRem(10),
    fz12: '12.25px !important',
    fz14: getRem(14),
    fz18: getRem(18),
    fz20: getRem(20),
    fz22: getRem(22),
    fz24: getRem(24),
    fz28: getRem(28),
    fz30: getRem(30),
    fz34: getRem(34),
    fz36: getRem(36),
    fz44_5: getRem(44.5),
    fz46: getRem(46),
    fz48: getRem(48),
  },
  deviceSizes: {
    mobile: `(max-width: ${viewportBreakpoints.mobile}) and (orientation: ${ORIENTATION.portrait})`,
    tablet: `(max-width: ${viewportBreakpoints.tablet}) and (orientation: ${ORIENTATION.portrait})`,
    tabletWithoutOrientation: `(max-width: ${viewportBreakpoints.tablet})`,
    laptop: `(max-width: ${viewportBreakpoints.laptop}) and (orientation: ${ORIENTATION.portrait})`,
    laptopWithoutOrientation: `(max-width: ${viewportBreakpoints.laptop})`,
    mobileLandScape: `(max-width: ${viewportBreakpoints.mobile}) and (max-height: ${viewportBreakpoints.mobile}) and (orientation: ${ORIENTATION.landscape})`,
    tabletLandScape: `(max-width: ${viewportBreakpoints.tablet}) and (max-height: ${viewportBreakpoints.mobile}) and (orientation: ${ORIENTATION.landscape})`,
    laptopLandScape: `(max-width: ${viewportBreakpoints.laptop}) and (max-height: ${viewportBreakpoints.mobile}) and (orientation: ${ORIENTATION.landscape})`,
    laptopL: `(max-width: ${viewportBreakpoints.laptopL})`,
    desktopS: `(max-width: ${viewportBreakpoints.desktopS})`,
    desktop: `(max-width: ${viewportBreakpoints.desktop})`,
    desktop1800: `(max-width: ${viewportBreakpoints.desktop1800})`,
    desktopMin1800: `(min-width: ${viewportBreakpoints.desktop1800})`,
    desktopM: `(max-width: ${viewportBreakpoints.desktopM})`,
    desktopL: `(max-width: ${viewportBreakpoints.desktopL})`,
  },
};

export default theme;
