import { useEffect } from 'react';
import { useLocation } from 'react-router';

import AboutUsWelcome from './components/AboutUsWelcome/AboutUsWelcome';
import Description from './components/Description/Description';

const AboutUs = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <AboutUsWelcome />
      <Description />
    </>
  );
};

export default AboutUs;
