import Fingertips from './components/Fingertips/Fingertips';
import SolutionWelcome from './components/SolutionWelcome/SolutionWelcome';
import UniqueSolution from './components/UniqueSolution/UniqueSolution';

const Solution = () => {
  return (
    <>
      <SolutionWelcome />
      <UniqueSolution />
      <Fingertips />
    </>
  );
};

export default Solution;
