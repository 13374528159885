import styled from 'styled-components';

import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Container = styled.div`
  display: flex;
  height: 100vh;

  @media ${theme.deviceSizes.tablet} {
    height: auto;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
  }
`;

const TopMobileArea = styled.div`
  display: none;
@media ${theme.deviceSizes.tablet} {
  width: 100%;
  display: block;
  position: absolute;
  top: 50px;
  height: ${getRem(104)};
  background-color: ${(props) => props.theme.colors.OceanBlue};
}
`;

const MobileLabel = styled(Typography)`
  position: absolute;
  top: ${getRem(89)};
  padding-left: ${getRem(75)};
  border-bottom: 1px solid ${theme.colors.White};
`;


const LeftSection = styled.div`
  display: flex;
  flex-basis: 38%;
  position: relative;
  padding-bottom: ${getRem(144)};
`;

const RightSection = styled.div`
  flex-basis: 62%;
  overflow: hidden;
  background-size: 100% auto;
  z-index: 1;
`;

const Image = styled.img`
  width: 100%;
  min-height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;

  @media ${theme.deviceSizes.tablet} {
    flex-basis: initial;
    /* object-position: initial; */
    min-height: 0;
    width: 100%;
    height: 375px;
    margin-bottom: -2%;
  }
`;

const BottomSection = styled.div`
  width: 100vw;
  bottom: 0;
  left: -100%;
  z-index: 0;
  position: absolute;
  height: ${getRem(104)};
  background-color: ${(props) => props.theme.colors.OceanBlue};

  @media ${theme.deviceSizes.tablet} {
    display: none;
  }
`;

export const S = {
  Image,
  Container,
  LeftSection,
  RightSection,
  BottomSection,
  TopMobileArea,
  MobileLabel,
};
