import theme from 'theme/theme';

import { IconsType } from './icon.types';

const ArrowIcon = ({
  width = 16,
  height = 26,
  color = theme.colors.White,
  ...props
}: IconsType) => (
  <svg fill='none' width={width} height={height} viewBox='0 0 16 26' {...props}>
    <path
      fill={color}
      d='M5.978 25.94H0l9.424-12.97L0 0h5.978l9.424 12.97-9.424 12.97Z'
    />
  </svg>
);

export default ArrowIcon;
