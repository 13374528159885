import { useRef } from 'react';

import { Navigation } from './Navigation';
import { S } from './Styles';
import { useDimensions } from './use-dimensions';

export const MobileMenu = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { height } = useDimensions(containerRef as never);

  return (
    <S.Container
      custom={height}
      ref={containerRef}
      animate={{ x: [-200, 0] }}
      transition={{ duration: 1 }}
    >
      <nav style={{ width: '100%' }}>
        <Navigation />
      </nav>
    </S.Container>
  );
};

export default MobileMenu;
