import { gsap, Power0 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import WhyUsImage from 'components/assets/images/WhyUs.png';
import ArrowDown from 'components/icons/ArrowDown.icon';
import ArrowRightSmall from 'components/icons/ArrowRightSmall.icon';
import ArrowUp from 'components/icons/ArrowUp.icon';
import ArrowLeft from 'components/icons/ArrowLeft.icon';
import Spacer from 'components/Spacer/Spacer';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { heightMobileBreakpoints, viewportBreakpoints } from 'utils/constants';
import { ORIENTATION } from 'utils/enums';
import {clearTriggerById, debounce} from 'utils/functions';
import { usePrevious } from 'utils/hooks/usePrevious';
import useViewport from 'utils/hooks/useViewport';
import useScrollTriggerRefresh from 'utils/hooks/useScrollTriggerRefresh';

import { S } from './SWhyUsWelcome';
import { slidesWhyUs } from './slides';

const opacity = [1, 0.49, 0.3, 0.14];

const WhyUsWelcome = () => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [itemsArray, setItemsArray] = useState<HTMLDivElement[] | null>(null);
  const [textWrap, setTextWrap] = useState<HTMLDivElement[] | null>(null);
  const [headers, setHeaders] = useState<HTMLDivElement[] | null>(null);
  const [slideWrapContent, setSlideWrapContent] = useState<
    HTMLDivElement[] | null
  >(null);
  const previousActiveSlide = usePrevious(activeSlide);

  const setActiveSlideDebounce = useRef(
    debounce((value) => setActiveSlide(value), 100)
  );

  const { width, height, orientation } = useViewport();
  useScrollTriggerRefresh();

  const isMobile = useMemo(
    () =>
      width < parseInt(viewportBreakpoints.tablet) ||
      (height < heightMobileBreakpoints &&
        orientation === ORIENTATION.landscape),
    [width, orientation, height]
  );

  const isLandscape = useMemo(
    () =>
      width <= parseInt(viewportBreakpoints.laptop) &&
      height < heightMobileBreakpoints &&
      orientation === ORIENTATION.landscape,
    [width, orientation, height]
  );

  const stepNumber: number = useMemo(() => {
    return isLandscape ? 0.85 : isMobile ? 0.65 : 0.85;
  }, [isMobile, isLandscape])

  const block_1 = useRef<HTMLDivElement>(null);
  const block_2 = useRef<HTMLDivElement>(null);
  const block_3 = useRef<HTMLDivElement>(null);
  // const block_4 = useRef<HTMLDivElement>(null);

  const text_wrap_1 = useRef(null);
  const text_wrap_2 = useRef(null);
  const text_wrap_3 = useRef(null);
  // const text_wrap_4 = useRef(null);

  const header_1 = useRef(null);
  const header_2 = useRef(null);
  const header_3 = useRef(null);
  // const header_4 = useRef(null);

  const slideWrapContent_1 = useRef(null);
  const slideWrapContent_2 = useRef(null);
  const slideWrapContent_3 = useRef(null);
  // const slideWrapContent_4 = useRef(null);

  const sliderRefsArray = useMemo(
    () => [block_1, block_2, block_3],
    // () => [block_1, block_2, block_3, block_4],
    []
  );
  const headerRefsArray = useMemo(
    () => [header_1, header_2, header_3],
    // () => [header_1, header_2, header_3, header_4],
    []
  );
  const textWrapRefsArray = useMemo(
    () => [text_wrap_1, text_wrap_2, text_wrap_3],
    // () => [text_wrap_1, text_wrap_2, text_wrap_3, text_wrap_4],
    []
  );
  const slideWrapContentRefsArray = useMemo(
    () => [
      slideWrapContent_1,
      slideWrapContent_2,
      slideWrapContent_3,
      // slideWrapContent_4,
    ],
    []
  );

  const arrowUpHandler = (e: any) => {
    e.preventDefault();

    if (itemsArray && activeSlide > 0) {
      setActiveSlideDebounce.current(previousActiveSlide - 1);
      const scrollToTop: number = (
        document.querySelector('.section-wrap') as any
      )?.offsetTop;

      const height: number = (sliderRefsArray[0].current as any).offsetHeight;

      gsap.to(window, {
        ease: Power0.easeNone,
        duration: 1,
        scrollTo: {
          y: (activeSlide - 1) * height * stepNumber + scrollToTop,
        },
      });
    }
  };

  const arrowDownHandler = (e: any) => {
    e.preventDefault();

    if (itemsArray && activeSlide < itemsArray.length - 1) {
      setActiveSlideDebounce.current(previousActiveSlide + 1);
      const scrollToTop: number = (
        document.querySelector('.section-wrap') as any
      )?.offsetTop;

      const height: number = (sliderRefsArray[0].current as any).offsetHeight;

      const newScreen: number = activeSlide + 1;
      const lastScreenActive: boolean = newScreen === itemsArray.length - 1;

      const positionFix: number =
        lastScreenActive && window.innerHeight !== height ? height - window.innerHeight : 0;
      gsap.to(window, {
        ease: Power0.easeNone,
        duration: 1,
        scrollTo: {
          y: (newScreen * height ) * stepNumber + scrollToTop - positionFix,
        },
      });
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    ScrollTrigger.config({ ignoreMobileResize: true });

    const isSlidesExist = block_1.current && block_2.current;
    // const isSlidesExist = block_1.current && block_2.current && block_3.current;
    const isTextsExist =
      text_wrap_1.current && text_wrap_2.current;
      // text_wrap_1.current && text_wrap_2.current && text_wrap_3.current;

    if (isSlidesExist && isTextsExist) {
      const slides: HTMLDivElement[] = gsap.utils.toArray('.section-card');
      setItemsArray(slides);

      const parags: HTMLDivElement[] = gsap.utils.toArray('.text-ccard');
      setTextWrap(parags);

      const headers: HTMLDivElement[] = gsap.utils.toArray('.header-ccard');
      setHeaders(headers);

      const slideWrapContents: HTMLDivElement[] =
        gsap.utils.toArray('.slide-wrap');
      setSlideWrapContent(slideWrapContents);
    }
  }, []);

  const onUpdateAnimation = (self: ScrollTrigger): void => {
    const currentSlideIndex =
      self.progress <= 0.21
        ? 0
        : self.progress <= 0.51
        ? 1
        : 2;
      // : self.progress <= 0.82
      // ? 2
      // : 3;

    setActiveSlideDebounce.current(currentSlideIndex);
  };

  const initScroll = () => {
    clearTriggerById('whyUs');

    const tl = gsap.timeline({
      scrollTrigger: {
        id: 'whyUs',
        pin: true,
        scrub: true,
        end: '+=200%',
        start: 'center center',
        trigger: '.section-container',
        onUpdate: onUpdateAnimation,
      },
    });

    let startSlide = (slideWrapContent?.[0]?.clientHeight as any) / 1.2;

    itemsArray?.forEach((panel, i, array) => {
      if (i === 0) return;

      if (array.length - 1 !== i) {
        tl.to(
          array[i - 1],
          {
            opacity: 0,
          },
          '>+=1.5'
        );
      }
      startSlide += 100;

      tl.fromTo(
        panel,
        {
          opacity: opacity[i],
          y: startSlide,
        },
        {
          opacity: 1,
          y: 0,
        }
      );

      tl.add('start')
        .fromTo(
          (headers as HTMLDivElement[])[i],
          { opacity: 0 },
          { color: theme.colors.White, opacity: 1 },
          'start'
        )
        .fromTo(
          textWrap?.[i] as any,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            yPercent: 0,
          },
          'start'
        );
    });

    return () => {
      tl.removeLabel('start');
      tl.kill();
    };
  };

  useEffect(() => {
    if (itemsArray && textWrap && headers && slideWrapContent) {
      ScrollTrigger.matchMedia({
        '(max-width: 767px) and (orientation: portrait)': initScroll,
        '(max-width: 992px) and (max-height: 576px) and (orientation: landscape)':
          initScroll,
        '(min-width: 768px) and (min-height: 576px)': () => {
          clearTriggerById('whyUs');

          const tl = gsap.timeline({
            scrollTrigger: {
              id: 'whyUs',
              pin: true,
              scrub: true,
              end: '+=400%',
              start: 'center center',
              trigger: '.section-container',
              onUpdate: onUpdateAnimation,
            },
          });

          let startSlide = (slideWrapContent?.[0]?.clientHeight as any) / 1.2;

          itemsArray?.forEach((panel, i, array) => {
            if (i === 0) return;

            if (array.length - 1 !== i) {
              tl.to(
                array[i - 1],
                {
                  opacity: 0,
                },
                '>+=1.5'
              );
            }
            startSlide += 100;

            tl.add('start')
              .fromTo(
                panel,
                {
                  opacity: opacity[i],
                  y: startSlide,
                },
                {
                  opacity: 1,
                  y: 0,
                },
                'start'
              )
              .fromTo(
                (headers as HTMLDivElement[])[i],
                { color: theme.colors.Black, opacity: 1 },
                { color: theme.colors.White },
                'start'
              );

            tl.fromTo(
              textWrap?.[i] as any,
              {
                opacity: 0,
              },
              {
                opacity: 1,
                yPercent: 0,
              }
            );
          });

          return () => {
            tl.removeLabel('start');
            tl.kill();
          };
        },
      });
    }
  }, [itemsArray, textWrap, headers, slideWrapContent]);

  const initScrollImage = () => {
    clearTriggerById('scrollImage');
    clearTriggerById('scrollHideImage');

    const timeline = gsap.timeline({
      defaults: {
        ease: 'power1',
      },
      scrollTrigger: {
        id: 'scrollImage',
        scrub: true,
        end: 'bottom',
        start: 'top',
        trigger: '.section-wrap',
      },
    });

    const timelineHideImage = gsap.timeline({
      defaults: {
        ease: 'power1',
      },
      scrollTrigger: {
        id: 'scrollHideImage',
        scrub: true,
        end: 'bottom+=100px',
        start: 'bottom+=100px',
        trigger: '.section-wrap',
      },
    });

    timelineHideImage.to('#why-us-image', {
      opacity: 0,
    });

    timeline.to('#why-us-image', {
      duration: 1,
      yPercent: -40,
    });

    return () => {
      timeline.kill();
      timelineHideImage.kill();
    };
  }

  useEffect(() => {
    ScrollTrigger.matchMedia({
      '(max-width: 767px) and (orientation: portrait)': initScrollImage,
      '(max-width: 992px) and (max-height: 576px) and (orientation: landscape)':
      initScrollImage,
      '(min-width: 768px) and (min-height: 576px)': () => {
        clearTriggerById('scrollImage');

        const timeline = gsap.timeline({
          defaults: {
            ease: 'power1',
          },
          scrollTrigger: {
            id: 'scrollImage',
            scrub: true,
            end: 'bottom',
            start: 'top',
            trigger: '.section-wrap',
          },
        });

        timeline.to('#why-us-image', {
          duration: 1,
          yPercent: -30,
        });

        return () => {
          timeline.kill();
        };
      }
    });
  }, []);

  return (
    <S.SectionWrap className='section-wrap'>
      <S.SpacerBlock />
      <S.Container className='section-container'>
        <S.LeftSection>
          <S.OrangeBlock>
            <S.ArrowContainer>
              <S.Button disabled={activeSlide === 0} onClick={arrowUpHandler}>
                <ArrowUp />
              </S.Button>
              <S.Button disabled={activeSlide === 3} onClick={arrowDownHandler}>
                <ArrowDown />
              </S.Button>
            </S.ArrowContainer>
          </S.OrangeBlock>
          <S.SliderContainer>
            {slidesWhyUs.map((el, i) => (
              <S.StickyWrapper
                key={i}
                id={`part-${i}`}
                className='section-card'
                ref={sliderRefsArray[i]}
              >
                <S.Block
                  ref={slideWrapContentRefsArray[i]}
                  className='slide-wrap'
                >
                  <Typography
                    fontWeight={500}
                    fontColor='white'
                    text={el.mainTitle}
                    lineHeight={isMobile ? 26 : 34.5}
                    fontSize={isMobile ? 'fz24' : 'fz28'}
                    reffer={headerRefsArray[i]}
                    className='header-ccard'
                  />
                  <S.TextBlock
                    ref={textWrapRefsArray[i]}
                    className='text-ccard'
                  >
                    <Spacer
                      height={30}
                      // height={
                      //   isMobile && i === 0
                      //     ? 30
                      //     : isMobile && i < 3
                      //     ? isLandscape
                      //       ? 30
                      //       : 95
                      //     : isMobile
                      //     ? 70
                      //     : 40
                      // }
                    />
                    <div>
                      <Typography
                        text={el.title}
                        fontWeight={400}
                        fontSize={isMobile ? 'fz14' : 'fz18'}
                        fontColor='white'
                        lineHeight={isMobile ? 16 : 7}
                      />
                      <Spacer height={20} />
                      <Typography
                        text={el.text}
                        fontWeight={300}
                        fontSize={isMobile ? 'fz14' : 'fz18'}
                        fontColor={theme.colors.WhiteGreen}
                        lineHeight={isMobile ? 18 : 26}
                      />
                    </div>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: isMobile ? '8px' : 40,
                      }}
                    >
                      <S.LearnMoreBtn
                        onClick={() => {
                          console.log('Link clicked:', { i });
                        }}
                      >
                        <Link
                          to={el.link}
                          style={{ display: 'flex', alignItems: 'baseline' }}
                        >
                          <Typography
                            text='Learn more'
                            fontWeight={isMobile ? 300 : 400}
                            fontColor='white'
                            fontSize={isMobile ? 'fz18' : 'fz24'}
                            lineHeight={isMobile ? 24 : 30}
                          />
                          {isMobile ? (
                            <ArrowRightSmall style={{ marginLeft: '32px' }} />
                          ) : (
                            <ArrowLeft style={{ marginLeft: '10px' }} />
                          )}
                        </Link>
                      </S.LearnMoreBtn>
                      <S.Line />
                    </div>
                  </S.TextBlock>
                </S.Block>
              </S.StickyWrapper>
            ))}
            <S.StickyWrapper
              id={`part-end`}
              className='section-card'
            ></S.StickyWrapper>
          </S.SliderContainer>
        </S.LeftSection>
      </S.Container>
      <S.ImageSector id='why-us-image'>
        <S.Image src={WhyUsImage} alt='WhyUs' />
      </S.ImageSector>
    </S.SectionWrap>
  );
};
export default WhyUsWelcome;
