import {useEffect, useRef} from 'react';
import {debounce} from '../functions';
import useViewport from './useViewport';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

const useScrollTriggerRefresh = (): void => {
  const afterUpdate = useRef(debounce((allScrollTriggers) => {
    allScrollTriggers.forEach((scrollTrigger: ScrollTrigger) => scrollTrigger.refresh());
  }, 900));

  const beforeUpdate = useRef(debounce(() => {
    const allScrollTriggers: ScrollTrigger[] = ScrollTrigger.getAll();
    allScrollTriggers.forEach((scrollTrigger: ScrollTrigger) => scrollTrigger.refresh());
    afterUpdate.current(allScrollTriggers);
  }, 800));
  const { width } = useViewport();

  useEffect(() => {
    beforeUpdate.current();
  }, [width])
};

export default useScrollTriggerRefresh;
