import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router';

import Footer from 'layout/Footer/Footer';
import Header from 'layout/Header/Header';

type LayoutProps = {
  children: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const isAuth = true;
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  const handleOverScroll = () => {
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

    if (window.scrollY + 1 > scrollHeight - window.innerHeight + 20) {
      window.scrollTo({
        top: document.body.scrollHeight - window.innerHeight,
        behavior: 'smooth',
      });
    }
  };
  onscroll = handleOverScroll;

  useEffect(() => {
    document.documentElement.addEventListener('touchmove', (event) => {
      event.preventDefault();
      return false;
    });
  }, []);

  return (
    <>
      <Header isLoginPage={isLoginPage} />
      {children}
      {isAuth && !isLoginPage ? <Footer /> : null}
    </>
  );
};

export default Layout;
