import { gsap, Power0 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useEffect, useMemo, useRef, useState } from 'react';

import ArrowDown from 'components/icons/ArrowDown.icon';
import ArrowUp from 'components/icons/ArrowUp.icon';
import theme from 'theme/theme';
import { heightMobileBreakpoints, viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import { sliderItems } from './slides';
import { S } from './SHomePageListOfScopes';
import useScrollTriggerRefresh from 'utils/hooks/useScrollTriggerRefresh';
import { ORIENTATION } from 'utils/enums';
import { usePrevious } from 'utils/hooks/usePrevious';
import { clearTriggerById, debounce } from 'utils/functions';

const slideNames = [
  { title: 'Upgrade', id: 'one' },
  // { title: 'Create', id: 'two' },
  { title: 'Customize', id: 'two' },
  { title: 'Manage', id: 'three' },
];

const HomePageListOfScope = () => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [isLastVisible, setIsLastVisible] = useState<boolean>(false);
  const [itemsArray, setItemsArray] = useState<HTMLDivElement[] | null>(null);
  const previousActiveSlide = usePrevious(activeSlide);

  const setActiveSlideDebounce = useRef(
    debounce((value) => {
      setIsLastVisible(value === 3);
      setActiveSlide(value);
    }, 50)
  );

  const { width, height, orientation } = useViewport();
  useScrollTriggerRefresh();

  const isMobile = useMemo(
    () =>
      width < parseInt(viewportBreakpoints.tablet) ||
      (height < heightMobileBreakpoints &&
        orientation === ORIENTATION.landscape),
    [width, orientation, height]
  );

  const isLandscape = useMemo(
    () => orientation === ORIENTATION.landscape,
    [width, orientation, height]
  );

  const card_1 = useRef(null);
  const card_2 = useRef(null);
  const card_3 = useRef(null);
  // const card_4 = useRef(null);

  // const sliderRefsArray = useMemo(() => [card_1, card_2, card_3, card_4], []);
  const sliderRefsArray = useMemo(() => [card_1, card_2, card_3], []);

  const arrowUpHandler = (e: any) => {
    e.preventDefault();

    if (itemsArray && activeSlide > 0) {
      setActiveSlideDebounce.current(previousActiveSlide - 1);

      const scrollToTop: number = (
        document.querySelector('.items-container') as any
      )?.offsetTop;

      const height: number = (sliderRefsArray[0].current as any).offsetHeight;

      gsap.to(window, {
        ease: Power0.easeNone,
        duration: 1,
        scrollTo: {
          y: scrollToTop + height * (activeSlide - 1),
        },
      });
    }
  };

  const arrowDownHandler = (e: any) => {
    e.preventDefault();

    if (itemsArray && activeSlide < itemsArray.length - 1) {
      setActiveSlideDebounce.current(previousActiveSlide + 1);
      const scrollToTop: number = (
        document.querySelector('.items-container') as any
      )?.offsetTop;

      const height: number = (sliderRefsArray[0].current as any).offsetHeight;

      gsap.to(window, {
        ease: Power0.easeNone,
        duration: 1,
        scrollTo: {
          y: scrollToTop + height * (activeSlide + 1),
        },
      });
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    ScrollTrigger.config({ ignoreMobileResize: true });

    const isSlidesExist =
    // card_1.current && card_2.current && card_3.current && card_4.current;
    card_1.current && card_2.current && card_3.current;

    if (isSlidesExist) {
      const slides: HTMLDivElement[] = gsap.utils.toArray('.card');
      setItemsArray(slides);
    }
  }, []);

  useEffect(() => {
    if (itemsArray) {
      clearTriggerById('scroll-wrap');

      const tl = gsap.timeline({
        scrollTrigger: {
          id: 'scroll-wrap',
          scrub: true,
          end: (): string => {
            const height: number = (sliderRefsArray[0].current as any)
              .offsetHeight;

            return `${height * (sliderRefsArray.length - 1) + 3}px`;
          },
          start: `start start`,
          trigger: '.scroll-wrap',
          pin: true,
          invalidateOnRefresh: true,
          onUpdate: (self) => {
            const currentSlideIndex =
              self.progress <= 0.25
                ? 0
                : self.progress <= 0.5
                ? 1
                : 2;
            // : self.progress <= 0.75
            // ? 2
            // : 3;

            setActiveSlideDebounce.current(currentSlideIndex);
          },
        },
      });

      itemsArray.forEach((panel, i, array) => {
        if (i === 0) return;
        clearTriggerById(`listOfScopesItem-${i}`);

        const tlItem = gsap.timeline({
          scrollTrigger: {
            id: `listOfScopesItem-${i}`,
            scrub: true,
            start: `center center`,
            end: '+=80%',
            trigger: array[i - 1],
            invalidateOnRefresh: true,
          },
        });

        tlItem
          .add('end-animation')
          .to(
            array[i - 1],
            {
              yPercent: -10,
              ease: Power0.easeOut,
            },
            'end-animation'
          )
          .to(
            array[i - 1],
            {
              opacity: 0,
              ease: Power0.easeOut,
            },
            'end-animation+=0.04'
          );

        tlItem.fromTo(
          panel,
          {
            opacity: 0,
            yPercent: 10,
            ease: Power0.easeOut,
          },
          {
            opacity: 1,
            yPercent: isMobile ? -10 : 0,
            ease: Power0.easeOut,
          }
        );
      });

      return () => {
        tl.kill();
      };
    }
  }, [itemsArray]);

  return (
    <S.SlidersContainer className='items-container'>
      <S.ListSection>
        <S.ScrollSection className='scroll-wrap'>
          <S.Button onClick={arrowUpHandler}>
            <ArrowUp />
          </S.Button>
          <S.NavLinkContainer>
            <S.NavLink>
              {slideNames.map((slide, i) => (
                <S.LinkHref
                  key={i}
                  href={void 0}
                  isLastVisible={i === 0 && isLastVisible}
                >
                  <S.LinkItem isActive={i === activeSlide} text={slide.title} />
                </S.LinkHref>
              ))}
            </S.NavLink>
          </S.NavLinkContainer>
          <S.Button onClick={arrowDownHandler} disabled={activeSlide === 3}>
            <ArrowDown />
          </S.Button>
        </S.ScrollSection>
      </S.ListSection>
      <S.Content>
        {sliderItems.map((slide, i) => (
          <S.SliderSection
            i={i}
            key={i}
            className='card'
            ref={sliderRefsArray[i]}
          >
            <S.SliderCard slide={i}>
              <S.ImageSection src={slide.image} alt='' />
            </S.SliderCard>
            <S.SliderTextContainer>
              <S.SliderTitleTextBlock>
                {isMobile && i < 2 ? slide.titleTextMobile : slide.titleText}
              </S.SliderTitleTextBlock>
              <S.SliderSmallTextBlock
                text={
                  isMobile && slide.smallTextMobile && !isLandscape
                    ? slide.smallTextMobile
                    : slide.smallText
                }
                fontWeight='300'
                fontColor={theme.colors.White}
                fontSize={!isMobile ? 'fz18' : 'fz14'}
                lineHeight={!isMobile ? 'fz26' : 'fz18'}
              />
            </S.SliderTextContainer>
          </S.SliderSection>
        ))}
      </S.Content>
    </S.SlidersContainer>
  );
};

export default HomePageListOfScope;
