import * as React from 'react';

import { IconsType } from './icon.types';

const Play = (props: IconsType) => (
  <svg
    width={105}
    height={121}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M105 60.5L0.749994 120.689L0.75 0.311229L105 60.5Z'
      fill='#262626'
    />
  </svg>
);

export default Play;
