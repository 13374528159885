import theme from 'theme/theme';

import { IconsType } from './icon.types';

const ArrowLeft = ({
  width = 13,
  height = 21,
  color = theme.colors.White,
  ...props
}: IconsType) => (
  <svg fill='none' width={width} height={height} viewBox='0 0 13 21' {...props}>
    <path
      fill={color}
      d='M8.17522 0.000499479L12.8672 0.000499889L5.47137 10.1797L12.8672 20.3589L8.17522 20.3589L0.779396 10.1797L8.17522 0.000499479Z'
    />
  </svg>
);

export default ArrowLeft;
