import { motion } from 'framer-motion';
import styled from 'styled-components';

import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Container = styled(motion.div)<{ selected: boolean }>`
  overflow: hidden;
  min-width: ${getRem(300)};
  height: ${getRem(450)};
  padding-top: ${getRem(65)};
  padding-left: ${getRem(70)};
  padding-bottom: ${getRem(40)};
  margin-left: ${(props) => props.theme.spacers.primaryLeft};

  background-color: ${(props) => props.theme.colors.White};

  @media ${theme.deviceSizes.desktop} {
    height: ${getRem(520)};
  }

  @media ${theme.deviceSizes.desktopL} {
    max-width: ${getRem(575)};
    height: ${getRem(604)};
  }

  @media ${theme.deviceSizes.desktop1800} {
    max-width: initial;
    min-width: ${getRem(450)};
    height: ${getRem(750)};
  }

  @media ${theme.deviceSizes.desktopM} {
    height: ${getRem(700)};
  }

  @media ${theme.deviceSizes.desktopS} {
    height: ${getRem(750)};
  }

  @media ${theme.deviceSizes.laptopL} {
    min-width: ${getRem(410)};
    height: ${getRem(700)};
    padding-left: ${getRem(60)};
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    min-width: ${getRem(400)};
    height: ${getRem(650)};
    padding-left: ${getRem(70)};
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    width: auto;
    padding-left: ${getRem(58)};
    padding-top: ${getRem(24)};
    height: ${getRem(530)};
    margin: 0 20px;
  }

  @media ${theme.deviceSizes.mobile} {
    min-width: initial;
    height: ${getRem(543)};
  }
  /*
  @media ${theme.deviceSizes.laptopLandScape} {
    height: ${getRem(523)};
  } */
`;

const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    margin-top: ${getRem(20)};
    flex-direction: column;
    align-items: flex-start;
    justify-content: initial;
    max-width: ${getRem(222)};

    & > * {
      margin-top: ${getRem(40)};

      &:last-child {
        margin-top: 0;
      }
    }
  }

  @media ${theme.deviceSizes.mobile} {
    & > * {
      margin-top: ${getRem(15)};

      &:last-child {
        margin-top: 0;
      }
    }
  }
`;

const TextContent = styled.div`
  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    order: 1;
  }
`;

const SubTitle = styled(Typography)<{ selected: boolean }>`
  opacity: ${(props) => (props.selected ? 1 : 0.4)};
  margin-top: ${getRem(20)};

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    width: ${getRem(222)};
    opacity: 1;
  }
`;

const Content = styled(Typography)<{ selected: boolean }>`
  opacity: ${(props) => (props.selected ? 1 : 0.4)};
  padding-right: ${getRem(115)};
  width: 100%;
  margin-top: ${getRem(10)};
  transition: 0s;

  @media ${theme.deviceSizes.desktop1800} {
    padding-right: ${getRem(70)};
  }

  @media ${theme.deviceSizes.laptopL} {
    width: ${getRem(350)};
    margin-top: 0;
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    width: 87%;
    margin-top: 0;
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    opacity: 0.4;
    padding-right: 58px;
    width: 90%;
  }

  @media ${theme.deviceSizes.mobile} {
    width: auto;
  }

  /* @media ${theme.deviceSizes.laptopLandScape} {
    width: auto;
  } */
`;

const Image = styled.img`
  margin-top: ${getRem(-20)};
  margin-right: ${getRem(11)};
  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    order: 0;
    margin-left: -6%;
    margin-top: ${getRem(-25)};
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    margin-left: ${getRem(-30)};
  }

  @media ${theme.deviceSizes.mobile} {
    margin-top: ${getRem(-25)};
  }
`;

export const S = {
  Container,
  TopContent,
  SubTitle,
  Content,
  TextContent,
  Image,
};
