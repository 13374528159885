import theme from 'theme/theme';

import { IconsType } from './icon.types';

const ArrowButtonMobile = ({
  width = 8,
  height = 11,
  color = theme.colors.White,
  ...props
}: IconsType) => (
  <svg fill='none' width={width} height={height} viewBox='0 0 8 11' {...props}>
    <path
      d='M3.283 10.555H.9l3.756-4.988L.9.579h2.383L7.04 5.567l-3.756 4.988Z'
      fill={color}
    />
  </svg>
);

export default ArrowButtonMobile;
