import { motion } from 'framer-motion';
import { NavLink as RNavLink } from 'react-router-dom';
import styled from 'styled-components';

import { getRem } from 'utils/functions';

const Container = styled(motion.div)`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 9999;
  overflow: hidden;
  position: absolute;
  background: ${(props) => props.theme.colors.White};
`;

const NavLink = styled(RNavLink)`
  display: block;
  font-weight: 300;
  text-decoration: none;
  color: ${(props) => props.theme.colors.Black};
  font-size: ${(props) => props.theme.fontSizes.fz18};

  &.active {
    font-weight: 400;
  }
`;

const NavLinkSection = styled.div`
  & > li {
    padding-top: ${getRem(21)};
    padding-left: ${getRem(21)};
    height: ${getRem(38)};
    margin-bottom: ${getRem(25)};
  }
`;

export const S = {
  Container,
  NavLink,
  NavLinkSection,
};
