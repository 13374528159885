import theme from 'theme/theme';

import { IconsType } from './icon.types';

const FolderIcon = ({
  width = 171,
  height = 122,
  color = theme.colors.Yellow,
  ...props
}: IconsType) => {
  return (
    <svg
      fill='none'
      width={width}
      height={height}
      viewBox='0 0 171 122'
      {...props}
    >
      <path
        d='M128.658 28.007 108.987.907H.524V121.69h169.952V28.007h-41.818Zm-39.341-16.95h14.453l12.302 16.95h-14.454l-6.09-8.486-6.211-8.465Zm-78.643 0h66.097l6.09 8.464 6.09 8.486H10.674v-16.95ZM160.326 111.54H10.674V38.157h149.652v73.384Z'
        fill={color}
      />
    </svg>
  );
};

export default FolderIcon;
