import styled from 'styled-components';
import {getRem} from 'utils/functions';
import theme from 'theme/theme';

const SectionWrap = styled.div`
  position: relative;
  overflow: hidden;
`;

const SpacerBlock = styled.div`
  height: ${getRem(212)};

  @media ${theme.deviceSizes.desktopS} {
    height: ${getRem(180)};
  }

  @media ${theme.deviceSizes.tablet} {
    height: ${getRem(50)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    height: ${getRem(110)};
  }
`;

const Container = styled.div`
  width: 100%;
  height: max-content;
  display: flex;
  position: relative;
`;

const LeftSection = styled.div`
  display: flex;
  height: 100vh;
  flex-basis: 100%;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  z-index: 3;
`;

const StickyWrapper = styled.div`
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  padding-top: ${getRem(150)};
  
  @media ${theme.deviceSizes.tablet} {
    padding-top: ${getRem(180)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    padding-top: ${getRem(10)};
    margin-top: ${getRem(80)};
  }
`;

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${getRem(37)} ${getRem(110)} 0 ${(props) => props.theme.spacers.primaryLeft};
  width: ${getRem(720)};
  height: ${getRem(448)};

  @media ${theme.deviceSizes.laptopL} {
    padding: ${getRem(40)} ${getRem(100)} 0 5%;
    width: ${getRem(600)};
  }

  @media ${theme.deviceSizes.tablet} {
    padding-left: ${getRem(77)};
    padding-right: ${getRem(50)};
    height: ${getRem(420)};
    width: 80%;
  }

  @media ${theme.deviceSizes.mobile} {
    padding: ${getRem(25)} ${getRem(16)} ${getRem(34)} ${getRem(77)};
    width: 80%;
    height: ${getRem(455)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    width: 100%;
    padding: ${getRem(15)} ${getRem(16)} ${getRem(24)} ${getRem(77)};
  }
`;

const OrangeBlock = styled.div`
  position: absolute;
  left: 0;
  background: ${theme.colors.Orange};
  max-width: ${getRem(720)};
  width: 100%;
  height: ${getRem(448)};
  top: ${getRem(150)};

  @media ${theme.deviceSizes.laptopL} {
    max-width: ${getRem(600)};
  }

  @media ${theme.deviceSizes.tablet} {
    max-width: 80%;
    top: ${getRem(180)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    max-width: 100%;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    top: ${getRem(80)};
    height: ${getRem(300)};
  }
`;

const TextBlock = styled.div`
  position: relative;
  padding-bottom: ${getRem(70)};
  height: 100%;
  
  @media ${theme.deviceSizes.laptopLandScape} {
    height: auto;
  }
`;

const RightSection = styled.div`
  bottom: -12%;
  /* padding-left: 10%; */
  max-height: 100vh;
  position: relative;

  @media ${theme.deviceSizes.tablet} {
    top: -32%;
    right: -14%;
    width: 100%;
    position: absolute;
  }
`;

const ArrowsSection = styled.div`
  display: none;

  @media ${theme.deviceSizes.mobile} {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-right: ${getRem(25)};

    & > * {
      margin-bottom: ${getRem(35)};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    display: flex;
    position: relative;
    flex-direction: column;
    
    & > * {
      margin-bottom: ${getRem(35)};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.8;
  }
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const LearnMoreBtn = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;

  a {
    text-decoration: none;
  }

  svg {
    margin-left: 30px;
  }

  @media ${theme.deviceSizes.tablet} {
    padding-bottom: ${getRem(4)};
  }
`;

const Line = styled.div`
  position: relative;
  width: ${getRem(320)};
  height: 1px;
  left: ${getRem(-185)};
  background-color: ${theme.colors.White};

  @media ${theme.deviceSizes.tablet} {
    left: ${getRem(-185)};
  }
`;

const ImageSector = styled.div`
  overflow: hidden;
  position: fixed;
  right: ${getRem(89)};
  top: 50px;
  z-index: -1;
  width: 50%;

  @media ${theme.deviceSizes.desktopS} {
    right: ${getRem(10)};
    width: 100%;
  }

  @media ${theme.deviceSizes.tablet} {
    top: ${getRem(110)};
    right: ${getRem(-100)};
    width: 100%;
  }

  @media ${theme.deviceSizes.mobile} {
    top: ${getRem(160)};
    right: ${getRem(-100)};
    width: 130%;
  }
`;

const Image = styled.img`
  width: 100%;
`;

const ArrowContainer = styled.div`
  position: absolute;
  z-index: 2;
  display: none;
  width: ${getRem(77)};
  padding: ${getRem(45)} 0;

  & > * {
    margin-bottom: ${getRem(35)};

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  @media ${theme.deviceSizes.mobile} {
    display: flex;
    flex-direction: column;
  }
  
  @media ${theme.deviceSizes.mobile} {
    padding: ${getRem(34)} 0;
  }
  
  @media ${theme.deviceSizes.laptopLandScape} {
    display: flex;
    flex-direction: column;
  }
`;

export const S = {
  SectionWrap,
  SpacerBlock,
  Container,
  LeftSection,
  StickyWrapper,
  Block,
  RightSection,
  Image,
  ArrowsSection,
  Button,
  SliderContainer,
  LearnMoreBtn,
  Line,
  TextBlock,
  ImageSector,
  ArrowContainer,
  OrangeBlock
}