import styled from 'styled-components';

import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;
  position: relative;
  flex-direction: column;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
`;

const PageLabelCard = styled.div`
  display: flex;
  width: max-content;
  white-space: nowrap;
  align-items: center;
  height: ${getRem(108)};
  justify-content: center;
  margin-top: ${getRem(90)};
  background-color: ${(props) => props.theme.colors.Yellow};
  padding-left: ${(props) => props.theme.spacers.primaryLeft};
  padding-right: ${(props) => props.theme.spacers.primaryLeft};

  @media ${theme.deviceSizes.laptopL} {
    width: 500px;
    padding-left: 11.5%;
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    width: calc(100% - 20px);
    padding-left: ${getRem(77)};
    justify-content: start;
    white-space: nowrap;
    height: ${getRem(48)};
  }

  /* @media ${theme.deviceSizes.laptopLandScape} {
    margin-top: ${getRem(30)};
    height: ${getRem(60)};
  } */
`;

export const S = {
  Container,
  Image,
  PageLabelCard,
};
