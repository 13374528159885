import { useMemo } from 'react';

import { privateRoutes } from 'app/Routes';
import { RouteTypes } from 'app/types';
import KeyIcon from 'components/icons/Key.icon';

import { S } from './Styles';

export const Navigation = () => {
  const navLinks = useMemo(() => {
    return privateRoutes.filter((el) => el.route !== RouteTypes.default);
  }, []);

  return (
    <ul>
      <S.NavLinkSection>
        {navLinks.map((el, i) => {
          return (
            <li key={i}>
              <S.NavLink to={el.route}>
                {el.name ? (
                  el.name
                ) : (
                  <>
                    <KeyIcon />
                    <span style={{ fontWeight: '400', lineHeight: '43px' }}>
                      &nbsp;&nbsp;Log In
                    </span>
                  </>
                )}
              </S.NavLink>
            </li>
          );
        })}
      </S.NavLinkSection>
    </ul>
  );
};
