import Spacer from 'components/Spacer/Spacer';

export const slidesWhyUs = [
  // {
  //   mainTitle: (
  //     <p>
  //       Tailored parking management solution. Made just for you.
  //       {/* <Spacer height={2} />
  //       Made For You. */}
  //     </p>
  //   ),
  //   // mainTitleMobile: (
  //   //   <p>
  //   //     Tailored parking management solution.
  //   //     <Spacer height={10} />
  //   //     Made for you.
  //   //   </p>
  //   // ),
  //   title: 'Every property is unique.',
  //   text: `Smart parking solutions must be designed with your property
  // in mind to benefit you and your customers. This is why our solutions are made
  //  just for you. Instead of a one-size-fits-all approach, we customize our pricing
  //  and solutions to fit your needs.`,
  //   link: './',
  //   spacer: 30,
  // },
  {
    mainTitle: 'Supercharged savings.',
    title: 'Oh. So. Huge.',
    text: (
      <p>
        We use automation tools to reduce operational costs.
        <p>Get your overhead costs down by more than 80% of what you pay now!</p>
      </p>
    ),
    link: './',
    // spacer: 83,
    spacer: 30,
  },
  {
    mainTitle: 'New revenue streams.',
    title: 'Transform your parking experience',
    text: `Our data analytics can help you get a clearer understanding of vehicle
    behaviors, traffic trends, and space utilization to make better decisions & earn more.`,
    link: './',
    spacer: 95,
  },
  {
    mainTitle: 'Introducing zero5. The future of parking.',
    // mainTitleMobile: 'Introducing zero5 - The Future Of Parking',
    title: '21st Century Technology',
    text: `At zero5, we are here to help you meet the demands of an ever-changing mobility market.
    By turning parking spaces into usable storage sites, we empower you to leverage
    every inch of the property to maximize profit.`,
    link: './',
    spacer: 67,
  },
];
