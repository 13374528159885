import { snap } from '@popmotion/popcorn';
import {
  animate,
  motion,
  MotionValue,
  useMotionValue,
  useTransform,
} from 'framer-motion';
import React, { createContext, useContext } from 'react';

import ArrowDown from 'components/icons/Arrow-Down.icon';
import ArrowUp from 'components/icons/Arrow-Up.icon';
import ChartIcon from 'components/icons/Chart.icon';
import CloudIcon from 'components/icons/Cloud.icon';
import FolderIcon from 'components/icons/Folder.icon';
import theme from 'theme/theme';

import Card, { CardProps } from '../Card/Card';
import { S } from './SSlider';

export const slidersContent: CardProps[] = [
  {
    headTitle: 'Connectivity at its best.',
    headSubTitle: 'API. API. API.',
    content: `Restructure your revenue streams by digitalizing your parking spaces with third-party solutions. 
      Offer premium experiences for your customers and leverage the power of your parking real estate by multi-purposing your property.`,
    icon: <ChartIcon width={195} height={180} />,
    iconMobile: <ChartIcon width={110} height={100} />,
  },
  {
    headTitle: 'Cloud-based parking management solution.',
    headSubTitle: 'Super powerful.',
    content: `Easily access everything with zero5 solution. 
      From reports, data analytics, revenue control to event parking planning and valet control, 
      avoid the struggles of the traditional ways, 
      and get all thes power under one roof with zero5 solutions.
    `,
    icon: <CloudIcon width={180} height={180} />,
    iconMobile: <CloudIcon width={120} height={85} />,
  },
  {
    headTitle: 'Smartest valet solution.',
    headSubTitle: 'Better than paper.',
    content: `Our smart valuet solution offers everything you need for exceptional parking experience.
      Our valet solution integrates with the parking system to create a seamless experience for both drivers and operators. 
      Our solution does everything from check-in to payment to vehicle requests in a single app.
    `,
    icon: <FolderIcon width={175} height={180} />,
    iconMobile: <FolderIcon width={128} height={90} />,
  },
];

type PagerViewContextProps = {
  initialOffset: number;
  trackSize: number;
  trackOffsetY: MotionValue;
};

const PagerViewContext = createContext<PagerViewContextProps>(null as never);

const usePagerContext = () => {
  const contextValue = useContext(PagerViewContext);

  if (!contextValue) return;

  return contextValue;
};

const viewSize = 600;

function View({ el, index }: { el: CardProps; index: number }) {
  const {
    initialOffset,
    trackSize,
    trackOffsetY,
  } = usePagerContext() as PagerViewContextProps;

  const viewOffsetY = useTransform(trackOffsetY, (value) => {
    let position = initialOffset + value;

    while (position > trackSize - viewSize) {
      position = position - trackSize;
    }

    while (position < 0 - viewSize) {
      position = position + trackSize;
    }

    return position;
  });

  return <Card y={viewOffsetY} {...el} index={index} />;
}

function PagerView({
  children,
  setCurrentSlide,
}: {
  children: React.ReactNode;
  setCurrentSlide: React.Dispatch<React.SetStateAction<number>>;
}) {
  const trackSize = slidersContent
    .slice(0)
    .reduce((total) => total + viewSize, 0);

  const trackOffsetY = useMotionValue(0);

  const snapTo = snap(viewSize);
  const moveTrackPosition = (amount: number) => {
    const nextOffsetY = trackOffsetY.get() + amount;

    animate(trackOffsetY, snapTo(nextOffsetY), {
      type: 'spring',
      damping: 80,
      stiffness: 400,
    });
  };

  const handleUp = () => {
    moveTrackPosition(viewSize);
    setCurrentSlide((prev: number) => (!prev ? 2 : prev - 1));
  };

  const handleDown = () => {
    moveTrackPosition(-viewSize);
    setCurrentSlide((prev: number) => (prev === 2 ? 0 : prev + 1));
  };

  return (
    <div style={{ position: 'relative' }}>
      <S.ButtonRow>
        <S.RoundBtn onClick={handleUp}>
          <ArrowUp color={theme.colors.White} />
        </S.RoundBtn>

        <S.RoundBtn style={{ marginTop: 13 }} onClick={handleDown}>
          <ArrowDown color={theme.colors.White} />
        </S.RoundBtn>
      </S.ButtonRow>

      <div style={{ overflow: 'hidden' }}>
        <S.SliderRow>
          {React.Children.map(children, (child, index) => (
            <PagerViewContext.Provider
              key={index}
              value={{
                initialOffset: index * viewSize,
                trackSize,
                trackOffsetY,
              }}
            >
              {child}
            </PagerViewContext.Provider>
          ))}
          <motion.div
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onUpdate={(point: any) => {
              trackOffsetY.set(point.y);
            }}
            style={{
              position: 'absolute',
              inset: 0,
            }}
          />
        </S.SliderRow>
      </div>

      <S.MobileButtons>
        <S.RoundBtn onClick={handleUp}>
          <ArrowUp color={theme.colors.Yellow} />
        </S.RoundBtn>

        <S.RoundBtn style={{ marginTop: 13 }} onClick={handleDown}>
          <ArrowDown color={theme.colors.Yellow} />
        </S.RoundBtn>
      </S.MobileButtons>
    </div>
  );
}

const Slider = ({
  setCurrentSlide,
}: {
  setCurrentSlide: React.Dispatch<React.SetStateAction<number>>;
}) => {
  return (
    <PagerView setCurrentSlide={setCurrentSlide}>
      {slidersContent.map((el, index) => (
        <View key={index} index={index} el={el} />
      ))}
    </PagerView>
  );
};

export default Slider;
