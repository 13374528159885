import styled from 'styled-components';

import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Container = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: space-between;

  @media ${theme.deviceSizes.tablet} {
    padding-left: ${getRem(12)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    padding-left: ${getRem(20)};
  }
`;

const LeftSection = styled.div`
  display: flex;
  height: 100vh;
  flex-basis: 100%;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  margin-left: ${theme.spacers.primaryLeft};

  @media ${theme.deviceSizes.laptopL} {
    margin-left: ${getRem(44)};
  }

  @media ${theme.deviceSizes.tablet} {
    z-index: 1;
    margin-right: 0;
    margin-left: 10px;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    z-index: 1;
    margin-right: 0;
    margin-left: 10px;
  }
`;

const StickyWrapper = styled.div`
  top: ${getRem(-70)};
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;

  @media ${theme.deviceSizes.tablet} {
    width: 70%;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    top: ${getRem(-20)};
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: ${getRem(425)};
  height: ${getRem(50)};

  @media ${theme.deviceSizes.tablet} {
    width: 100%;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    width: 100%;
  }
`;

const RightSection = styled.div`
  bottom: -12%;
  /* padding-left: 10%; */
  max-height: 100vh;
  position: relative;

  @media ${theme.deviceSizes.tablet} {
    top: -32%;
    z-index: 3;
    right: -14%;
    width: 100%;
    position: absolute;
  }
`;

const Image = styled.img`
  display: none;

  @media ${theme.deviceSizes.tablet} {
    right: 0;
    display: block;
    position: absolute;
    top: ${getRem(-180)};
  }
`;

const ImageDesktop = styled(Image)`
  width: ${getRem(1200)};
  margin-bottom: -30%;
  display: inline-block;

  @media ${theme.deviceSizes.desktop1800} {
    width: 50vw;
  };

  @media ${theme.deviceSizes.tablet} {
    display: none;
  }
`;

const ArrowsSection = styled.div`
  display: none;

  @media ${theme.deviceSizes.tablet} {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-right: ${getRem(12)};

    & > * {
      margin-bottom: ${getRem(35)};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    display: flex;
    position: relative;
    flex-direction: column;

    & > * {
      margin-bottom: ${getRem(35)};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
  &:disabled {
    opacity: 0.8;
  }
  
  @media ${theme.deviceSizes.laptopLandScape} {
    padding-left: 0;
  }
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const SHomePageDoMore = {
  Container,
  LeftSection,
  StickyWrapper,
  Block,
  RightSection,
  Image,
  ImageDesktop,
  ArrowsSection,
  Button,
  SliderContainer,
};
