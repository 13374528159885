export enum RouteTypes {
  aboutUs = '/about-us',
  whyUs = '/why-us',
  solution = '/solution',
  contactUs = '/contact-us',
  default = '/',
  login = '/login',
  termsOfUse = '/policies/terms-of-use',
  privacyPolicy = '/policies/privacy-policy',
}
