import { motion } from 'framer-motion';
import styled from 'styled-components';

import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Container = styled.div`
  position: relative;
  margin-top: ${getRem(44)};
  margin-bottom: ${getRem(200)};

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    margin-bottom: 0;
  }

  /* @media ${theme.deviceSizes.laptopLandScape} {
    margin-bottom: ${getRem(30)};

  } */
`;

const RoundBtn = styled.div`
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  width: ${getRem(48)};
  height: ${getRem(48)};
  justify-content: center;
  background-color: ${(props) => props.theme.colors.White};
`;

const SliderRow = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  position: relative;
  height: ${getRem(605)};
`;

const ButtonRow = styled.div<{ leftPosition: number }>`
  display: flex;
  width: max-content;
  position: absolute;
  left: ${(props) => getRem(props.leftPosition + 567)};
  bottom: 106%;

  @media ${theme.deviceSizes.desktopM} {
    left: ${(props) => getRem(props.leftPosition + 473)};
  }
  @media ${theme.deviceSizes.desktopS} {
    left: ${(props) => getRem(props.leftPosition + 395)};
  }
  @media ${theme.deviceSizes.laptopL} {
    left: ${(props) => getRem(props.leftPosition + 170)};
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    position: static;
    margin-top: ${getRem(30)};
    margin-bottom: ${getRem(57)};
    margin-left: auto;
    padding-right: ${getRem(20)};
  }
`;

const P = styled.p`
  margin-bottom: ${getRem(20)};

  @media ${theme.deviceSizes.mobile} {
    margin-bottom: ${getRem(10)};
  }
`;

const CounterContainer = styled.div`
  width: auto;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  background-color: ${theme.colors.White};
  margin-left: 20px !important;
  margin-right: 20px !important;
`;

const CounterLine = styled.div<{ active: boolean }>`
  width: 33%;
  background-color: ${({ active }) =>
    active ? theme.colors.Yellow : theme.colors.WhiteGreen};
  height: ${getRem(4)};
`;

export const S = {
  Container,
  ButtonRow,
  RoundBtn,
  SliderRow,
  P,
  CounterContainer,
  CounterLine,
};
