import { motion } from 'framer-motion';
import styled from 'styled-components';

import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Container = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  height: ${getRem(1059)};
  position: relative;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.White};
  padding-left: ${(props) => props.theme.spacers.primaryLeft};

  @media ${theme.deviceSizes.desktop1800} {
    height: ${getRem(899)};
  }

  @media ${theme.deviceSizes.laptopL} {
    padding-left: 0;
    margin-left: -12px;
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    height: ${getRem(829)};
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    height: initial;
    min-height: 80vh;
    flex-direction: column;
    justify-content: start;
    margin-left: initial;
  }
`;

const LeftSection = styled.div`
  display: flex;
  flex-basis: 51.5%;
  flex-direction: column;

  @media ${theme.deviceSizes.desktop1800} {
    flex-basis: 32%;
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    order: 1;
    margin-top: -400px;
    flex-basis: initial;
  }

  /* @media ${theme.deviceSizes.laptopLandScape} {
    flex-basis: 20%;
  } */
`;

const RightSection = styled.div`
  display: flex;
  flex-basis: 57%;
  flex-direction: column;
  justify-content: start;
  margin-left: 10%;
  margin-top: ${getRem(250)};

  @media (max-width: 3008px) {
    margin-left: 13%;
  }

  @media (max-width: 2340px) {
    margin-left: 11%;
  }

  @media (max-width: 2056px) {
    margin-left: 10%;
  }

  @media (max-width: 1920px) {
    margin-left: 9.4%;
  }

  @media ${theme.deviceSizes.desktop1800} {
    flex-basis: 57%;
    margin-left: 9%;
    margin-top: ${getRem(232)};

  }

  @media ${theme.deviceSizes.laptopL} {
    margin-left: ${getRem(65)}
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    margin-left: ${getRem(-80)};
    margin-top: ${getRem(220)};
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    order: 0;
    flex-basis: initial;
    padding-left: ${getRem(77)};
    margin: initial;
    justify-content: center;
    margin-top: 0;
  }

  /* @media ${theme.deviceSizes.laptopLandScape} {
    margin-top: 25%;
  } */
`;

const Title = styled(Typography).attrs({
  fontWeight: '300',
  fontColor: theme.colors.Yellow,
})`
  width: max-content;
  margin-top: ${getRem(86)};

  @media ${theme.deviceSizes.laptopL} {
    margin-left: 9.5%;
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    width: ${getRem(222)};
    margin-top: ${getRem(54)};
    margin-bottom: ${getRem(26)};
    font-size: 24px !important;
    margin-left: ${getRem(77)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    margin-left: ${getRem(20)};
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin-top: ${getRem(26)};

  @media ${theme.deviceSizes.laptopL} {
    margin-left: 10%;
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    width: 75%;
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    /* margin-left: 20px; */
    width: 100%;
    margin-left: initial;
    margin: 0 auto;
    text-align: center;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    margin-left: 20px;
  }
`;

const MobileWrapper = styled.img`
  top: ${getRem(-10)};
  left: ${getRem(-31)};
  z-index: 1;
  position: absolute;

  @media ${theme.deviceSizes.desktop1800} {
    width: 100%;
    left: ${getRem(-22)};
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    left: ${getRem(-14)};
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    max-width: 375px;
    left: initial;
    position: relative;
    transform: translateY(49%);
  }
`;

const AnimateImage = styled(motion.div)<{ width: number }>`
  top: 11.5em;
  left: 3.1em;
  position: absolute;
  z-index: 0;

  @media ${theme.deviceSizes.desktop1800} {
    width: 85%;
    left: 5%;
    top: 8em;
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    top: 5em;
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    width: 375px;
    left: ${(props) => props.width / 2 - 182}px;
  }

  /* @media ${theme.deviceSizes.laptopLandScape} {
    top: 18%;
  } */
`;

const MobileSlide = styled.img`
  width: 100%;
  bottom: 100px;

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    transform: translateY(3%);
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    transform: translateY(59%);
    width: 84%;
  }
`;

export const S = {
  AnimateImage,
  Container,
  LeftSection,
  RightSection,
  Title,
  MobileContainer,
  MobileWrapper,
  MobileSlide,
};
