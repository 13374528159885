import { useCallback, useMemo } from 'react';

import Typography from 'components/Typography/Typography';
import PageLabel from 'layout/PageLabel/PageLabel';
import theme from 'theme/theme';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import ContactUsImage from '../assets/ContactUsWelcome.png';
import ContactUsImageMobile from '../assets/ContactUsWelcomeMobile_hr.png';
import { S } from './SContactUsWelcome';

const ContactUsWelcome = () => {
  const { width } = useViewport();

  const isMobile = useMemo(
    () => width < parseInt(viewportBreakpoints.tablet),
    [width]
  );

  const renderCustomText = useCallback(() => {
    return (
      <div>
        <Typography
          text='Connect'
          fontSize='fz28'
          fontWeight={500}
          lineHeight={34.5}
          fontColor={theme.colors.OceanBlue}
        />
        <Typography
          text='With Us'
          fontSize='fz28'
          fontWeight={500}
          lineHeight={34.5}
          fontColor={theme.colors.OceanBlue}
        />
      </div>
    );
  }, []);

  return (
    <S.Container>
      <S.TopMobileArea />
      {isMobile ? (
        <S.MobileLabel
          text='Connect with us'
          fontColor={'white'}
          fontSize='fz24'
          fontWeight='lighter'
          lineHeight='30'
        />
      ) : (
        <PageLabel CustomComponent={renderCustomText()} />
      )}
      <S.LeftSection>
        <S.BottomSection />
      </S.LeftSection>
      <S.RightSection>
        <S.Image
          src={isMobile ? ContactUsImageMobile : ContactUsImage}
          alt='Contact us Welcome'
        />
      </S.RightSection>
    </S.Container>
  );
};

export default ContactUsWelcome;
