import { motion } from 'framer-motion';
import styled from 'styled-components';

import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const RoundBtn = styled.div`
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  width: ${getRem(48)};
  height: ${getRem(48)};
  justify-content: center;
  background-color: ${(props) => props.theme.colors.Yellow};

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    background-color: ${(props) => props.theme.colors.White};
  }
`;

const SliderRow = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  position: relative;
  height: ${getRem(600)};

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    height: ${getRem(600)};
  }
`;

const ButtonRow = styled.div`
  z-index: 9;
  display: flex;
  position: absolute;
  width: max-content;
  flex-direction: column;
  right: ${(props) => props.theme.spacers.primaryLeft};
  top: ${getRem(1)};

  @media ${theme.deviceSizes.desktop1800} {
    top: 0;
  }

  @media ${theme.deviceSizes.desktopS} {
    right: 40px;
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    right: 30px;
    top: 0;
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    display: none;
  }
`;

const MobileButtons = styled.div`
  display: none;

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    top: 0;
    display: block;
    position: absolute;
    left: ${getRem(-70)};
  }
`;

export const S = {
  ButtonRow,
  RoundBtn,
  SliderRow,
  MobileButtons,
};
