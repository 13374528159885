import { useMemo } from 'react';

import Spacer from 'components/Spacer/Spacer';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import { S } from './SCard';
import { CardProps } from '../Slider/NSlider';

const Card = ({
  content,
  imgLink,
  headTitle,
  headSubTitle,
  selected = false,
  i,
}: CardProps) => {
  const { width } = useViewport();

  const isMobile = useMemo(
    () => width <= parseInt(viewportBreakpoints.tablet),
    [width]
  );

  return (
    <S.Container selected={selected} transition={{ duration: 0.5 }}>
      <S.TopContent>
        <S.TextContent>
          <div style={{ marginTop: i === 0 ? '-8px' : '' }}>
            <Typography
              text={headTitle}
              fontWeight='500'
              lineHeight={isMobile ? '26' : '34.5'}
              fontColor={theme.colors.Yellow}
              fontSize={isMobile ? 'fz24' : 'fz28'}
            />
          </div>
          <S.SubTitle
            fontSize={isMobile ? 'fz18' : 'fz24'}
            lineHeight={isMobile ? '24' : '30'}
            fontWeight='300'
            selected={selected}
            text={headSubTitle}
          />
        </S.TextContent>
        {isMobile ? (
          <div style={{ height: '60px' }}>
            <S.Image src={imgLink} width={118} />
          </div>
        ) : (
          <div
            style={{
              marginTop: i === 1 ? '-14px' : i === 2 ? '-30px' : '-3px',
            }}
          >
            <S.Image src={imgLink} />
          </div>
        )}
      </S.TopContent>

      <Spacer height={isMobile ? 10 : 37} />

      <S.Content
        text={content}
        selected={selected}
        lineHeight={isMobile ? '18' : '26'}
        fontSize={isMobile ? 'fz14' : 'fz18'}
        fontWeight='300'
      />
    </S.Container>
  );
};

export default Card;
