import theme from 'theme/theme';

import { IconsType } from './icon.types';

const MenuBurgerIcon = ({
  width = 27,
  height = 25,
  color = theme.colors.Black,
  ...props
}: IconsType) => {
  return (
    <svg width={width} height={height} fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 .749h26.708v2.136H0V.75Zm.176 21.366h26.708v2.137H.176v-2.137Zm26.708-7.122H.176v2.137h26.708v-2.137ZM.176 7.871h26.708v2.136H.176V7.871Z'
        fill={color}
      />
    </svg>
  );
};

export default MenuBurgerIcon;
