import { AnimatePresence } from 'framer-motion';
import { useMemo, useState } from 'react';

import Spacer from 'components/Spacer/Spacer';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import MobileWrapperImage from '../../assets/MobileWrapper.png';
import MobileSlide1 from '../../assets/MobileScreen1.png';
import MobileSlide2 from '../../assets/MobileScreen2.png';
import { S } from './SFingertips';
import Slider from './Slider/Slider';

const mobileImages = [MobileSlide1, MobileSlide2, MobileSlide2];

const Fingertips = () => {
  const { width } = useViewport();

  const [currentSlide, setCurrentSlide] = useState(0);

  const isMobile = useMemo(
    () => width <= parseInt(viewportBreakpoints.tablet),
    [width]
  );
  return (
    <S.Container>
      {isMobile && (
        <S.Title fontSize={'fz24'} lineHeight={26} text='Insight at your fingertips' />
      )}

      <S.LeftSection>
        {!isMobile && (
          <>
            <S.Title fontSize={'fz46'} lineHeight={56.5} text='Insight at your fingertips' />
            <Spacer height={30} />
          </>
        )}
        <S.MobileContainer>
          <S.MobileWrapper
            src={MobileWrapperImage}
            alt='Mobile wrapper image'
          />
          <AnimatePresence key={currentSlide}>
            <S.AnimateImage
              width={width}
              initial={{ opacity: 0, y: 800 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: {
                  mass: 0.1,
                  type:  'linear',
                  stiffness: 300,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <S.MobileSlide
                src={mobileImages[currentSlide]}
                alt='Mobile slide'
              />
            </S.AnimateImage>
          </AnimatePresence>
        </S.MobileContainer>
      </S.LeftSection>
      <S.RightSection>
        <Slider setCurrentSlide={setCurrentSlide} />
      </S.RightSection>
    </S.Container>
  );
};

export default Fingertips;
