import { IconsType } from './icon.types';

const ArrowDown = (props: IconsType) => (
  <svg
    width={26}
    height={16}
    fill='#ffffff'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M-.001 6.576V.598l12.97 9.423L25.94.598v5.978L12.97 16 0 6.576Z'
      fill={props.fill}
    />
  </svg>
);

export default ArrowDown;
