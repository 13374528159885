import theme from 'theme/theme';

import { IconsType } from './icon.types';

const ArrowDown = ({
  width = 21,
  height = 13,
  color = theme.colors.White,
  ...props
}: IconsType) => (
  <svg fill='none' width={width} height={height} viewBox='0 0 21 13' {...props}>
    <path
      fill={color}
      d='M1.1239e-05 4.69197L1.14441e-05 -8.89893e-07L10.1792 7.39582L20.3584 0L20.3584 4.69197L10.1792 12.0878L1.1239e-05 4.69197Z'
    />
  </svg>
);

export default ArrowDown;
