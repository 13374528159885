import { motion, Variants } from 'framer-motion';
import { ComponentType } from 'react';
import styled from 'styled-components';

import { pageTransitionVariants, pageAnimationParams } from 'utils/constants';

const WithRouterAnimation = <T extends Variants,>(
  WrappedComponent: ComponentType<T>,
  animationParams: Variants = pageTransitionVariants
): React.ComponentType<T> => {
  const AnimatedComponent = (props: T) => (
    <MotionContainer
      exit='out'
      animate='in'
      initial='initial'
      variants={animationParams}
      transition={pageAnimationParams}
    >
      <WrappedComponent {...props} />
    </MotionContainer>
  );

  return AnimatedComponent;
};

const MotionContainer = styled(motion.div)`
  top: 0;
  width: 100%;
  height: 100%;
`;

export default WithRouterAnimation;
