import * as React from 'react'
import { IconsType } from './icon.types';

const MenuBurgerOpen = (props: IconsType) => (
  <svg
    width={25}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.02 21.777 2.242 0 .5 1.742 22.277 23.52l1.743-1.743Z'
      fill='#333'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.277 0 .5 21.778l1.742 1.742L24.02 1.742 22.277 0Z'
      fill='#333'
    />
  </svg>
)

export default MenuBurgerOpen;
