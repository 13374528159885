import { useEffect, useMemo, useRef, useState } from 'react';
import { Player } from 'video-react';

import Play from 'components/icons/Play.icon';
import PlayerBackground from 'components/assets/images/Player-Background.png';
import PlaySmall from 'components/icons/PlaySmall.icon';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import 'video-react/dist/video-react.css';

import { S } from './Styles';

const HomePagePlayer = () => {
  const PlayerRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isPlayerVisible, setIsPlayerVisible] = useState<boolean>(false);
  const [playerTop, setPlayerTop] = useState<number>(0);

  const { width } = useViewport();

  const isMobile = useMemo(
    () => width <= parseInt(viewportBreakpoints.tablet),
    [width]
  );

  const topElement = document.getElementById('player');

  document.onfullscreenchange = () => {
    topElement?.scrollIntoView(true);
    window.scrollBy(0, -100);
  };

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const y = window.scrollY;
      if (playerTop && Math.abs(y - playerTop) > 300) {
        (PlayerRef as any).current?.pause();
        setIsPlaying(false);
      }
    });
  }, [playerTop]);


  const handlePlayer = () => {
    const y = window.scrollY;
    setPlayerTop(y);
    setIsPlaying(true);
    setIsPlayerVisible(true);
  };

  return (
    <>
      <S.Container>
        {!isPlayerVisible ? (
          <S.PlayerBackground>
            <img
              src={`${PlayerBackground}`}
              width={width}
              alt='Player-Background'
            />
            <S.PlayIcon>
              {isMobile ? (
                <PlaySmall onClick={handlePlayer} />
              ) : (
                <Play onClick={handlePlayer} />
              )}
            </S.PlayIcon>
          </S.PlayerBackground>
        ) : (
          <S.PlayerContainer id='player'>
            <Player
              ref={PlayerRef}
              src={
                'https://z5-assets.s3.us-west-1.amazonaws.com/videos/z5_intro_BW.mp4'
              }
              autoPlay={isPlaying}
            />
          </S.PlayerContainer>
        )}
      </S.Container>
    </>
  );
};

export default HomePagePlayer;
