import theme from 'theme/theme';

import { IconsType } from './icon.types';

const ChartIcon = ({
  width = 210,
  height = 210,
  color = theme.colors.Yellow,
  ...props
}: IconsType) => {
  return (
    <svg
      fill='none'
      width={width}
      height={height}
      viewBox='0 0 200 190'
      {...props}
    >
      <path
        d='M78.312 39.828 23.694 130.12l7.188 4.348 54.617-90.291-7.187-4.348Z'
        fill={color}
      />
      <path
        d='m87.98 38.55-7.956 2.694 44.097 130.202 7.956-2.695L87.981 38.55Z'
        fill={color}
      />
      <path
        d='m187.84 89.782-63.001 77.689 6.524 5.291 63.001-77.689-6.524-5.29ZM27.3 142.799c6.263 0 11.34-5.077 11.34-11.34s-5.077-11.34-11.34-11.34-11.34 5.077-11.34 11.34 5.077 11.34 11.34 11.34ZM83.16 52.08c6.263 0 11.34-5.077 11.34-11.34S89.423 29.4 83.16 29.4s-11.34 5.078-11.34 11.34c0 6.263 5.077 11.34 11.34 11.34Z'
        fill={color}
      />
      <path
        d='M128.1 180.6c6.263 0 11.34-5.077 11.34-11.34s-5.077-11.34-11.34-11.34-11.34 5.077-11.34 11.34 5.077 11.34 11.34 11.34ZM191.1 102.481c6.263 0 11.34-5.077 11.34-11.34S197.363 79.8 191.1 79.8s-11.34 5.077-11.34 11.34 5.077 11.34 11.34 11.34Z'
        fill={color}
      />
    </svg>
  );
};

export default ChartIcon;
