import { motion } from 'framer-motion';
import { NavLink as RNavLink } from 'react-router-dom';
import styled from 'styled-components';

import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Container = styled.header`
  z-index: 998;
  width: 100vw;
  display: flex;
  align-items: center;
  height: ${getRem(72)};
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.White};
  padding-left: ${(props) => props.theme.spacers.primaryLeft};
  border-top: 1px solid ${(props) => props.theme.colors.Black};
  border-bottom: 1px solid ${(props) => props.theme.colors.Black};

  @media ${theme.deviceSizes.laptopL} {
    padding-left: 5%;
  }

  @media ${theme.deviceSizes.tablet} {
    width: 100%;
    border-top: none;
    height: ${getRem(68)};
    padding: 0 ${getRem(20)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    width: 100%;
    border-top: none;
    height: ${getRem(68)};
    padding: 0 ${getRem(20)};
  }
`;

const ContainerMobileMenu = styled(motion.div)`
  @media ${theme.deviceSizes.tablet} {
    z-index: 998;
    width: 100vw;
    border-top: none;
    height: ${getRem(209)};
    margin-top: ${getRem(68)};
  }
  @media ${theme.deviceSizes.laptopLandScape} {
    z-index: 998;
    width: 100vw;
    border-top: none;
    height: ${getRem(209)};
    margin-top: ${getRem(68)};
  }
`;

const NavSection = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  padding-bottom: ${getRem(3)};

  & > li {
    margin-right: ${getRem(30)};
  }

  @media ${theme.deviceSizes.tablet} {
    display: none;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    display: none;
  }
`;

const Image = styled.img`
  width: ${getRem(86)};
  padding-top: ${getRem(29)};
  padding-bottom: ${getRem(25)};

  @media ${theme.deviceSizes.tablet} {
    width: ${getRem(48)};
    padding-top: initial;
    padding-bottom: initial;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    width: ${getRem(48)};
    height: ${getRem(13)};
    padding-top: initial;
    padding-bottom: initial;
  }
`;

const VL = styled.div`
  width: 1px;
  height: ${getRem(71)};
  margin-top: ${getRem(3)};
  background-color: ${(props) => props.theme.colors.Black};

  @media ${theme.deviceSizes.tablet} {
    display: none;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    display: none;
  }
`;

const NavLink = styled(RNavLink)<{isLoginLink?: boolean,}>`
  font-weight: ${props => props.isLoginLink ? 400 :300};
  text-decoration: none;
  padding-right: ${getRem(40)};
  color: ${(props) => props.isLoginLink ? props.theme.colors.MainGreen : props.theme.colors.Black};
  font-size: ${(props) => props.theme.fontSizes.fz18};
  line-height: ${props => props.isLoginLink ? '43px' : '63.5px'};

  &.active {
    font-weight: 400;
  }

  @media ${theme.deviceSizes.laptopL} {
    padding-right: ${getRem(20)};
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    padding-right: 0;
    font-size: ${(props) => props.theme.fontSizes.fz14};
  }

`;

const LoginWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: ${getRem(210)};
  justify-content: center;

  @media ${theme.deviceSizes.tablet} {
    display: none;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    display: none;
  }
`;

const BurgerContainer = styled.div`
  display: none;
  padding-bottom: ${getRem(2)};

  @media ${theme.deviceSizes.tablet} {
    display: block;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    display: block;
  }
`;

export const SHeader = {
  Container,
  VL,
  Image,
  LoginWrapper,
  NavLink,
  NavSection,
  BurgerContainer,
  ContainerMobileMenu,
};
