import { useMemo } from 'react';
import { useNavigate } from 'react-router';

import Spacer from 'components/Spacer/Spacer';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import CameraRender from '../../assets/CameraRender.png';
import CameraRenderMobile from '../../assets/CameraRender_mobile.png';
import WelcomeImage from '../../assets/WelcomeImage.png';
import WelcomeImageMobile from '../../assets/WelcomeImageMobile.png';
import { S } from './Styles';

const AboutUsWelcome = () => {
  const navigate = useNavigate();
  const { width } = useViewport();

  const isMobile = useMemo(
    () => width < parseInt(viewportBreakpoints.tablet),
    [width]
  );

  const isMobileSmall = useMemo(
    () => width < parseInt(viewportBreakpoints.mobile),
    [width]
  );

  return (
    <>
      <S.MainContainer>
        <S.LeftSection>
          {!isMobile && <S.Label label='About Us' />}

          <S.TextBlock>
            <Typography
              lineHeight={isMobile ? '26' : '34.5'}
              fontWeight='500'
              fontColor={theme.colors.Blue}
              fontSize={isMobile ? 'fz24' : 'fz28'}
              text={(
                <>
                  <p>Future-proof parking spaces.</p>
                  <Spacer height={10} />
                  <p>This is the way.</p>
                </>
              )}              
              // text={
              //   isMobile ? (
              //     <>
              //       <p>Future-proof parking spaces.</p>
              //       <Spacer height={10} />
              //       <p>This is the way.</p>
              //     </>
              //   ) : (
              //     <>
              //       <p>Future-Proof Parking Spaces.</p>
              //       <p>This Is The Way.</p>
              //     </>
              //   )
              // }              
            />

            <Spacer height={25} />

            <Typography
              lineHeight={isMobile ? '18' : '26'}
              fontWeight='300'
              fontSize={isMobile ? 'fz14' : 'fz18'}
              text={
                <>
                  <p>
                    Make your operations swift and efficient with zero5
                    solutions.{isMobile ? <Spacer height={10} /> : ''} Our
                    solutions are 100% effective in monitoring individual parking
                    spaces without needing any additional resources.
                  </p>
                </>
              }
            />

            <Spacer height={25} />

            <S.ContactButton
              skew
              arrow
              title='Contact Us'
              onClick={() => {
                navigate('/contact-us', { replace: true });
              }}
            />
          </S.TextBlock>
        </S.LeftSection>
        <S.RightSection>
          {isMobile && (
            <S.MobileLabel
              text='About Us'
              fontSize='fz24'
              lineHeight='30'
              fontWeight='300'
              fontColor={theme.colors.White}
            />
          )}
          <S.Image src={isMobileSmall ? WelcomeImageMobile : WelcomeImage} alt='About us welcome' />
        </S.RightSection>
      </S.MainContainer>
      <S.CameraImage
        src={isMobileSmall ? CameraRenderMobile : CameraRender}
        alt='Camera render image'
      />
    </>
  );
};

export default AboutUsWelcome;
