import { MotionValue } from 'framer-motion';
import { useMemo } from 'react';

import Spacer from 'components/Spacer/Spacer';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import { S } from './SCard';

export type CardProps = {
  icon: JSX.Element;
  iconMobile: JSX.Element;
  headTitle: string;
  headSubTitle: string;
  content: string;
  index?: number;
  y?: MotionValue<number>;
};

const Card = ({
  content,
  headTitle,
  headSubTitle,
  icon,
  iconMobile,
  y,
  index,
}: CardProps) => {
  const { width } = useViewport();

  const isMobile = useMemo(
    () => width <= parseInt(viewportBreakpoints.tablet),
    [width]
  );

  const isLaptop = useMemo(
    () => width <= parseInt(viewportBreakpoints.laptop),
    [width]
  );

  const isLaptopL = useMemo(
    () => width <= parseInt(viewportBreakpoints.laptopL),
    [width]
  );


  return (
    <S.Container style={{ y }}>
      {isMobile ? (
        iconMobile
      ) : (
        <div
          style={{
            marginTop:
              index === 0 && isLaptop
                ? '-25px'
                : index === 0 && isLaptopL
                ? '-42px'
                : index === 0
                ? '-67px'
                : index === 1 && isLaptop
                ? '-20px'
                : index === 1
                ? '-28px'
                : index === 2 && isLaptop
                ? '-25px'
                : '-26px',
          }}
        >
          {icon}
        </div>
      )}

      <Spacer height={isMobile ? 23 : 26} />

      <Typography
        lineHeight={isMobile ? 26 : 44}
        text={headTitle}
        fontWeight={300}
        fontSize={isMobile ? 'fz24' : 'fz36'}
      />

      <Spacer height={isMobile ? 20 : 50} />

      <Typography
        fontWeight={isMobile ? 400 : 500}
        lineHeight={isMobile ? 16 : 34.5}
        text={headSubTitle}
        fontColor={theme.colors.Yellow}
        fontSize={isMobile ? 'fz14' : 'fz28'}
      />

      <Spacer height={isMobile ? 20 : 50} />

      <S.Description
        text={content}
        lineHeight={26}
        fontWeight={300}
        fontSize={isMobile ? 'fz14' : 'fz18'}
      />
    </S.Container>
  );
};

export default Card;
