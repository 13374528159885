import styled from 'styled-components';

import Button from 'components/Button/Button';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const MainContainer = styled.div`
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
  padding-top: ${getRem(117)};
  padding-bottom: ${getRem(158)};
  background-color: ${(props) => props.theme.colors.Blue};
  padding-left: ${(props) => props.theme.spacers.primaryLeft};

  @media ${theme.deviceSizes.laptopL} {
    padding-left: 2.5%;
    padding-top: ${getRem(85)};
  }

  @media ${theme.deviceSizes.tablet} {
    padding-left: ${getRem(77)};
    padding-right: ${getRem(19)};
    padding-bottom: ${getRem(85)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    padding-top: ${getRem(40)};
  }
`;

const DescriptionText = styled(Typography)`
  width: ${getRem(545)};
  z-index: 1;

  @media ${theme.deviceSizes.desktopS} {
    max-width: 65%;
  }

  @media ${theme.deviceSizes.laptopL} {
    margin-left: 26px;
  }

  @media ${theme.deviceSizes.tablet} {
    max-width: 100%;
    margin-left: initial;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    margin-left: 0;
  }
`;

const ContactButton = styled(Button)`
  margin-top: auto;
  height: ${getRem(64)};
  width: ${getRem(315)};
  background-color: transparent;
  color: ${(props) => props.theme.colors.White};
  border: 1px solid ${(props) => props.theme.colors.White};

  ::before,
  ::after {
    display: none;
  }

  @media ${theme.deviceSizes.laptopL} {
    margin-left: 26px;
  }

  @media ${theme.deviceSizes.tablet} {
    height: ${getRem(46)};
    width: ${getRem(164)};
    margin-left: initial;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    margin-left: initial;
  }
`;

const Image = styled.img`
  max-width: 72%;
  position: absolute;
  bottom: -1%;
  right: -19%;

  @media ${theme.deviceSizes.desktopM} {
    right: -31%;
  }

  @media ${theme.deviceSizes.tablet} {
    display: none;
  }
`;

const TitleText = styled(Typography)`
  @media ${theme.deviceSizes.laptopL} {
    margin-left: 26px;
  }

  @media ${theme.deviceSizes.tablet} {
    margin-left: initial;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    margin-left: initial;
  }
`;

export const S = {
  MainContainer,
  ContactButton,
  DescriptionText,
  Image,
  TitleText,
};
