import theme from 'theme/theme';

import { IconsType } from './icon.types';

const LinkedInIcon = ({
  width = 34,
  height = 34,
  color = theme.colors.White,
  ...props
}: IconsType) => {
  return (
    <svg
      fill='none'
      width={width}
      height={height}
      viewBox='0 0 34 34'
      {...props}
    >
      <path stroke={color} d='M.5.5h32.227v32.227H.5z' />
      <path
        d='M12.667 13.398v9.51H10.54v-9.51h2.127ZM10.4 10.902c0-.322.105-.588.316-.8.217-.216.516-.325.897-.325.375 0 .67.109.887.325.217.211.326.478.326.8 0 .317-.109.58-.326.791-.216.211-.512.317-.887.317-.381 0-.68-.106-.897-.317a1.073 1.073 0 0 1-.316-.79Zm6.688 4.527v7.48H14.97v-9.51h1.995l.123 2.03Zm-.378 2.373-.685-.009c.006-.674.1-1.292.281-1.854a4.566 4.566 0 0 1 .773-1.45 3.412 3.412 0 0 1 1.196-.932 3.523 3.523 0 0 1 1.547-.334c.457 0 .87.064 1.239.193.375.123.694.325.958.607.27.28.475.647.615 1.098.14.445.211.993.211 1.644v6.143h-2.127v-6.152c0-.457-.067-.818-.202-1.081-.129-.27-.32-.46-.571-.572-.246-.117-.554-.175-.923-.175a2.05 2.05 0 0 0-.976.228c-.287.153-.53.36-.73.624a3.022 3.022 0 0 0-.447.914 3.779 3.779 0 0 0-.159 1.108Z'
        fill={color}
      />
    </svg>
  );
};

export default LinkedInIcon;
