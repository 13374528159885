import { gsap, Power0 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { useRef, useEffect, useMemo, useState } from 'react';

import Zero5_Logo from 'components/assets/images/Zero.png';
import Zero5_Big from 'components/assets/images/Zero5_Logo_edited.png';
import ArrowDown from 'components/icons/ArrowDown.icon';
import ArrowUp from 'components/icons/ArrowUp.icon';
import Spacer from 'components/Spacer/Spacer';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import {heightMobileBreakpoints, viewportBreakpoints} from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import { SHomePageDoMore } from './SHomePageDoMore';
import { slides } from './slides';
import useScrollTriggerRefresh from 'utils/hooks/useScrollTriggerRefresh';
import {ORIENTATION} from 'utils/enums';
import {usePrevious} from 'utils/hooks/usePrevious';
import {clearTriggerById, debounce} from 'utils/functions';

const deltas = [0, 0.8, 1.2];

const HomePageDoMore = () => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const [itemsArray, setItemsArray] = useState<HTMLDivElement[] | null>(null);
  const [paragraphs, setParagraphs] = useState<HTMLDivElement[] | null>(null);
  const previousActiveSlide = usePrevious(activeSlide);

  const setActiveSlideDebounce = useRef(debounce((value) => setActiveSlide(value), 100));

  const { width, height,orientation } = useViewport();
  useScrollTriggerRefresh();

  const isMobile = useMemo(
    () => (width < parseInt(viewportBreakpoints.tablet) || (height < heightMobileBreakpoints && orientation === ORIENTATION.landscape)),
    [width, orientation, height]
  );

  const block_1 = useRef<HTMLDivElement>(null);
  const block_2 = useRef<HTMLDivElement>(null);
  const block_3 = useRef<HTMLDivElement>(null);

  const text_1 = useRef(null);
  const text_2 = useRef(null);
  const text_3 = useRef(null);

  const sliderRefsArray = useMemo(() => [block_1, block_2, block_3], []);
  const textsRefsArray = useMemo(() => [text_1, text_2, text_3], []);

  const refreshWindowHeight = () => {
    setWindowHeight(window.innerHeight);
  };

  const arrowUpHandler = (e: any) => {
    e.preventDefault();

    if (itemsArray && activeSlide > 0) {
      setActiveSlideDebounce.current(previousActiveSlide - 1);

      const scrollToTop: number = (
        document.querySelector('.section-wrap') as any
      )?.offsetTop;

      gsap.to(window, {
        ease: Power0.easeNone,
        duration: 1,
        scrollTo: {
          y: scrollToTop + windowHeight * (activeSlide - 1),
        },
      });
    }
  };

  const arrowDownHandler = (e: any) => {
    e.preventDefault();

    if (itemsArray && activeSlide < itemsArray.length - 1) {
      setActiveSlideDebounce.current(previousActiveSlide + 1);
      const scrollToTop: number = (
        document.querySelector('.section-wrap') as any
      )?.offsetTop;

      gsap.to(window, {
        ease: Power0.easeNone,
        duration: 1,
        scrollTo: {
          y: scrollToTop + windowHeight * (activeSlide + 1),
        },
      });
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    ScrollTrigger.config({ ignoreMobileResize: true });

    const isSlidesExist = block_1.current && block_2.current && block_3.current;
    const isTextsExist = text_1.current && text_2.current && text_3.current;

    if (isSlidesExist && isTextsExist) {
      const slides: HTMLDivElement[] = gsap.utils.toArray('.section-card');
      setItemsArray(slides);

      const parags: HTMLDivElement[] = gsap.utils.toArray('.text-ccard');
      setParagraphs(parags);
    }

    window.addEventListener('resize', refreshWindowHeight);

    return () => {
      window.removeEventListener('resize', refreshWindowHeight);
    };
  }, []);

  const onUpdateAnimation = (self: ScrollTrigger): void => {
    const currentSlideIndex =
        self.progress <= 0.15
            ? 0
            : self.progress <= 0.5
            ? 1
            : 2;

    setActiveSlideDebounce.current(currentSlideIndex);
  }

  const initScrollForMobile = () => {
    clearTriggerById('doMore');

    const tl = gsap.timeline({
      scrollTrigger: {
        id: 'doMore',
        pin: true,
        scrub: true,
        end: '+=200%',
        start: 'center center',
        trigger: '.section-container',
        onUpdate: onUpdateAnimation,
      },
    });

    (itemsArray as HTMLDivElement[]).forEach((panel, i, array) => {
      if (i === 0) return;

      tl.to(array[i - 1], {
        opacity: 0,
        yPercent: -30,
      });

      tl.fromTo(
        panel,
        {
          opacity: 0,
          yPercent: 35,
        },
        {
          opacity: 1,
          yPercent: 0,
        }
      );

      tl.fromTo(
        (paragraphs as HTMLDivElement[])[i],
        {
          opacity: 0,
        },
        {
          opacity: 1,
        }
      );
    });

    return () => {
      tl.kill();
    };
  }

  useEffect(() => {
    if (itemsArray && paragraphs) {
      ScrollTrigger.matchMedia({
        '(max-width: 768px) and (orientation: portrait)': initScrollForMobile,
        '(max-width: 992px) and (max-height: 576px) and (orientation: landscape)': initScrollForMobile,
        '(min-width: 768px) and (min-height: 576px)': function () {
          clearTriggerById('doMore');

          const tl = gsap.timeline({
            scrollTrigger: {
              id: 'doMore',
              pin: true,
              scrub: true,
              end: '+=200%',
              start: 'center center',
              trigger: '.section-container',
              onUpdate: onUpdateAnimation,
            },
          });

          itemsArray.forEach((panel, i, array) => {
            if (i === 0) return;

            tl.to(array[i - 1], {
              opacity: 0,
              yPercent: -30,
            });

            tl.fromTo(
              panel,
              {
                opacity: 0.3,
                yPercent: 35 * deltas[i],
              },
              {
                opacity: 1,
                yPercent: 0,
              }
            );

            tl.fromTo(
              paragraphs[i],
              {
                opacity: 0,
                yPercent: 35 * deltas[i],
              },
              {
                opacity: 1,
                yPercent: 0,
              }
            );
          });

          return () => {
            tl.kill();
          };
        },
      });
    }
  }, [itemsArray, paragraphs]);

  useEffect(() => {
    if (activeSlide !== previousActiveSlide) {
      ScrollTrigger.getById('doMore')?.refresh();
    }
  }, [activeSlide]);

  return (
    <div style={{ position: 'relative' }} className='section-wrap'>
      <SHomePageDoMore.Container className='section-container'>
        <SHomePageDoMore.ArrowsSection className='arrow'>
          <SHomePageDoMore.Button
            disabled={activeSlide === 0}
            onClick={arrowUpHandler}
          >
            <ArrowUp id='up' fill={theme.colors.Orange} />
          </SHomePageDoMore.Button>
          <SHomePageDoMore.Button
            disabled={activeSlide == 2}
            onClick={arrowDownHandler}
          >
            <ArrowDown id='downDoMore' fill={theme.colors.Orange} />
          </SHomePageDoMore.Button>
        </SHomePageDoMore.ArrowsSection>
        <SHomePageDoMore.LeftSection>
          <SHomePageDoMore.SliderContainer>
            {slides.map((el, i) => (
              <SHomePageDoMore.StickyWrapper
                key={i}
                id={`part-${i}`}
                className='section-card'
                ref={sliderRefsArray[i]}
              >
                <SHomePageDoMore.Block>
                  <Typography
                    lineHeight={isMobile ? '26' : '34.5'}
                    fontSize={isMobile ? 'fz24' : 'fz28'}
                    fontWeight='500'
                    text={isMobile ? el.titleMobile : el.title}
                    fontColor={theme.colors.Orange}
                  />

                  {!isMobile && <Spacer height={30} />}

                  <div>
                    <Typography
                      lineHeight={isMobile ? '18' : '26'}
                      fontWeight={'300'}
                      fontSize={isMobile ? 'fz14' : 'fz18'}
                      text={el.description}
                      reffer={textsRefsArray[i]}
                      className='text-ccard'
                      fontColor={theme.colors.Black}
                    />
                  </div>
                </SHomePageDoMore.Block>
              </SHomePageDoMore.StickyWrapper>
            ))}
          </SHomePageDoMore.SliderContainer>
        </SHomePageDoMore.LeftSection>
        <SHomePageDoMore.RightSection>
          <SHomePageDoMore.ImageDesktop src={Zero5_Big} alt='Zero5' />
        </SHomePageDoMore.RightSection>
      </SHomePageDoMore.Container>

      <SHomePageDoMore.Image src={Zero5_Logo} alt='Zero5' />
    </div>
  );
};

export default HomePageDoMore;
