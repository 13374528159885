import { useEffect } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import theme from 'theme/theme';

import HomePageListOfScopes from './components/HomePageListOfScopes/HomePageListOfScopes';
import HomePageDoMore from './components/HomePageDoMore/HomePageDoMore';
import HomePageWelcome from './components/HomePageWelcome/HomePageWelcome';
import HomePagePlayer from './components/HomePagePlayer/HomePagePlayer';

const Homepage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <HomePageWelcome />
      <HomePageListOfScopes />
      <DesktopPlayerContainer>
        <HomePagePlayer />
      </DesktopPlayerContainer>
      <HomePageDoMore />
      <MobilePlayerContainer>
        <HomePagePlayer />
      </MobilePlayerContainer>
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
`;

const DesktopPlayerContainer = styled.div`
  @media ${theme.deviceSizes.tablet} {
    display: none;
  }
`;

const MobilePlayerContainer = styled.div`
  display: none;
  @media ${theme.deviceSizes.tablet} {
    display: initial;
  }
`;

export default Homepage;
