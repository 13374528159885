import PageLabel from 'layout/PageLabel/PageLabel';
import styled from 'styled-components';
import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Label = () => {
  return (
    <>
      <StyledLabel label='Why zero5' />
    </>
  );
};

const StyledLabel = styled(PageLabel)`
  margin-top: 0;
  height: auto;
  position: absolute;
  top: ${getRem(218)};

  @media ${theme.deviceSizes.tablet} {
    top: ${getRem(89)};
    padding-left: ${getRem(77)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    top: ${getRem(90)};
  }
`;

export default Label;
