import { MouseEventHandler, useMemo } from 'react';
import styled, { CSSProperties } from 'styled-components';

import ArrowIconBig from 'components/icons/ArrowButton.icon';
import ArrowIcon from 'components/icons/ArrowButtonMobile.icon';
import theme from 'theme/theme';
import { viewportBreakpoints } from 'utils/constants';
import { getRem } from 'utils/functions';
import useViewport from 'utils/hooks/useViewport';

export type ButtonProps = {
  title: string;
  skew?: boolean;
  width?: number;
  height?: number;
  arrow?: boolean;
  className?: string;
  customSkewWidth?: string;
  themeColor?: CSSProperties['color'];
  arrowDirection?: 'left' | 'top' | 'bottom';
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const SKEW_ANGLE = 25;

const Button = ({
  title,
  arrow,
  className,
  width = 313,
  height = 49,
  skew = false,
  themeColor = theme.colors.Black,
  onClick,
}: ButtonProps) => {
  const skewWidth = useMemo(() => {
    if (!skew) {
      return 0;
    }

    return height * Math.tan((SKEW_ANGLE * Math.PI) / 180) * 2;
  }, [height, skew]);

  const widthScreen = useViewport();

  const isMobile = useMemo(
    () => widthScreen.width < parseInt(viewportBreakpoints.tablet),
    [widthScreen]
  );

  return (
    <SButton
      skew={skew}
      width={width}
      skewWidth={skewWidth}
      className={className}
      themeColor={themeColor}
      onClick={onClick}
    >
      {title}
      {arrow ? (isMobile ? <ArrowIcon /> : <ArrowIconBig />) : null}
    </SButton>
  );
};

const SButton = styled.button<{
  width: number;
  skew: boolean;
  skewWidth: number;
  themeColor: string;
}>`
  font-family: 'Roboto', sans-serif;
  padding: 0;
  z-index: 1;
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  height: ${getRem(57)};
  justify-content: center;
  transition: 50ms ease-out;
  color: ${(props) => props.theme.colors.White};
  background-color: ${(props) => props.themeColor};
  border: 1px solid ${(props) => props.themeColor};
  border-right: none;
  font-size: ${(props) => props.theme.fontSizes.fz24};
  width: ${(props) => getRem(props.width - props.skewWidth / 2)};
  font-weight: 300;
  backface-visibility: hidden;
  outline: 1px solid transparent;
  backface-visibility: hidden;
  will-change: transform;

  ::before {
    content: '';
    left: 0;
    height: 1px;
    position: absolute;
    transition: all 0.5s;
    bottom: ${getRem(-10)};
    transform-origin: left 2px;
    background-color: ${(props) => props.themeColor};
    width: calc(100% + ${(props) => (props.skewWidth + 6) / 2}px);

    @media ${theme.deviceSizes.tablet} {
      width: calc(100% + ${(props) => (props.skewWidth - 8) / 2}px);
    }
  }

  ::after {
    top: -1px;
    left: -1px;
    z-index: -1;
    width: 100%;
    content: ' ';
    display: block;
    position: absolute;
    height: calc(100% + 1px);
    transform-origin: top left;
    transition: 50ms ease-out;
    background-color: ${(props) => props.themeColor};
    border-right: 1px solid ${(props) => props.themeColor};
    border-bottom: 1px solid ${(props) => props.themeColor};
    transform: skew(${(props) => (props.skew ? SKEW_ANGLE : 0)}deg, 0deg);
  }

  :focus {
    background-color: transparent;
    color: ${(props) => props.themeColor};

    svg {
      path {
        fill: ${(props) => props.themeColor};
      }
    }

    ::after {
      background-color: transparent;
    }
  }

  &:hover {
    &::before {
      background-color: ${(props) => props.theme.colors.LightGreen};
    }
  }

  @media ${theme.deviceSizes.tablet} {
    font-size: ${(props) => props.theme.fontSizes.fz18};
    width: ${getRem(202)};
    height: ${getRem(39)};
  }
`;

export default Button;
