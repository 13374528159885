export type DoMoreType = {
  title: string;
  titleMobile: string;
  description: string | JSX.Element;
};

export const slides: DoMoreType[] = [
  {
    title: 'Do more. With less.',
    titleMobile: 'Do more. With less.',
    description: (
      <>
        <br></br>
        <p>
          Save up to 80% on parking operational costs with zero5's automated solutions.
          All our solutions seamlessly integrate with each other, so you don't have to.
        </p>
      </>
    ),
  },
  {
    title: 'Parking is outdated. It\'s time for an upgrade.',
    titleMobile: 'Parking is outdated. It\'s time for an upgrade.',
    description: (
      <>
        <br></br>
        <p>If you ever wondered if there was a better way to manage your garage. There probably is.</p>
        <p>Avoid the stress of manual management of your parking spaces. We can help.</p>
      </>
    ),
  },
  {
    title: 'Completely ticketless.',
    titleMobile: 'Completely ticketless.',
    description: (
      <>
        <br></br>
        <p>
          We utilize latest computer vision technology for ticketless parking entry.
        </p>
        <p>Our technology completely eliminates the inconvenience and inefficiency that comes with ticketing system.</p>
      </>
    ),
  },
];
