import Typography from 'components/Typography/Typography';
import styled from 'styled-components';
import theme from 'theme/theme';

import { getRem } from 'utils/functions';
const ListSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 15%;
  padding-left: ${(props) => props.theme.spacers.primaryLeft};
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  height: 100vh;

  @media ${theme.deviceSizes.laptopL} {
    padding-right: ${getRem(10)};
    padding-left: ${getRem(50)};
  }

  @media ${theme.deviceSizes.tablet} {
    margin-right: ${getRem(30)};
    margin-left: ${getRem(10)};
    flex-basis: initial;
    padding-left: initial;
    padding-right: initial;
    padding-bottom: 12%;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    padding-left: ${getRem(20)};
    flex-basis: initial;
  }
`;

const ScrollSection = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const SliderCard = styled.div<{ slide?: number }>`
  flex-basis: 47%;
  background-size: cover;

  & > img {
    width: 100%;
  }

  @media ${theme.deviceSizes.tablet} {
    flex-basis: auto;

    & > img {
      object-fit: fill;
      width: ${getRem(315)};
      margin-left: ${(props) =>
        props.slide === 0
          ? getRem(-35)
          : props.slide === 1
          ? getRem(-55)
          : props.slide === 2
          ? getRem(-50)
          : getRem(-40)};
    }
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    flex-basis: 35%;
  }
`;

const SliderTextContainer = styled.div`
  display: flex;
  flex-basis: 53%;
  align-self: center;
  flex-direction: column;
  width: ${getRem(559)};
  padding-right: ${getRem(20)};

  & > * {
    margin-bottom: ${getRem(30)};

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${theme.deviceSizes.laptop} {
    padding-right: ${getRem(20)};

    & > * {
      margin-bottom: ${getRem(20)};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media ${theme.deviceSizes.tablet} {
    width: ${getRem(230)};
    margin-right: ${getRem(30)};
    align-self: start;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    flex-basis: 65%;
    padding-right: ${getRem(20)};
  }

  @media ${theme.deviceSizes.mobile} {
    flex-basis: auto;
  }
`;

const SliderTitleTextBlock = styled.h3`
  max-width: ${getRem(560)};
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: ${theme.colors.White};
  align-self: start;

  @media ${theme.deviceSizes.desktopS} {
    font-size: ${theme.fontSizes.fz22};
    line-height: 28px;
  }

  @media ${theme.deviceSizes.tablet} {
    width: ${getRem(230)};
    font-size: ${theme.fontSizes.fz24};
    margin-top: ${getRem(50)};
    line-height: 26px;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    font-size: ${theme.fontSizes.fz20};
    line-height: 26px;
  }
`;
const SliderSmallTextBlock = styled(Typography)`
  align-self: flex-start;
  line-height: 26px;

  @media ${theme.deviceSizes.tablet} {
    width: ${getRem(230)};
    line-height: 18px;
  }
`;

const NavLinkContainer = styled.nav`
  height: ${getRem(190)};
  overflow: hidden;

  @media ${theme.deviceSizes.tablet} {
    display: none;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    display: none;
  }
`;

const NavLink = styled.nav`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: ${getRem(50)};

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${theme.deviceSizes.tablet} {
    & > * {
      margin-bottom: ${getRem(40)};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const Button = styled.button`
  padding: ${getRem(50)} 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }

  @media ${theme.deviceSizes.tablet} {
    padding: ${getRem(35)} 0 0 ${getRem(10)};
  }
`;

const LinkHref = styled.a<{
  isLastVisible: boolean;
}>`
  text-decoration: none;
  display: ${(props) => (props.isLastVisible ? 'none' : 'inherit')};
`;

const LinkItem = styled(Typography)<{
  isActive?: boolean;
}>`
  text-decoration: none;
  font-weight: 200;
  font-size: 24px;
  line-height: 30px;
  height: ${getRem(20)};
  color: ${(props) =>
    props.isActive ? theme.colors.LightGreen : theme.colors.White};
`;

const Image = styled.img`
  display: none;

  @media ${theme.deviceSizes.tablet} {
    right: 0;
    display: block;
    position: absolute;
    bottom: ${getRem(-210)};
  }
`;

const SlidersContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.MainGreen};

  @media ${theme.deviceSizes.tablet} {
    justify-content: start;
  }
`;

const Content = styled.div`
  width: 100vw;
  overflow: hidden;
  position: relative;
`;

const SliderSection = styled.div<{ i: number }>`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;

  @media ${theme.deviceSizes.tablet} {
    align-self: start;
    align-items: start;
    flex-direction: column;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    top: ${(props) =>
      props.i === 0
        ? '5%'
        : props.i === 1
        ? '18%'
        : props.i === 2
        ? '18%'
        : '10%'};
  }
  @media ${theme.deviceSizes.mobile} {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const ImageSection = styled.img`
  @media ${theme.deviceSizes.tablet} {
    padding-top: ${getRem(50)};
  }

  @media ${theme.deviceSizes.mobile} {
    padding-top: ${getRem(68)};
  }
`;

export const S = {
  SliderCard,
  ListSection,
  ScrollSection,
  SliderSection,
  SliderTextContainer,
  SliderTitleTextBlock,
  SliderSmallTextBlock,
  Button,
  NavLinkContainer,
  NavLink,
  LinkHref,
  LinkItem,
  Image,
  SlidersContainer,
  Content,
  ImageSection,
};
