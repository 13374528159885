import * as React from 'react';

import { IconsType } from './icon.types';

const PlaySmall = (props: IconsType) => (
  <svg
    width={22}
    height={25}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M21.1096 12.6462L0.695128 24.4325L0.695129 0.859901L21.1096 12.6462Z'
      fill='#262626'
    />
  </svg>
);

export default PlaySmall;
