import styled from 'styled-components';

import Button from 'components/Button/Button';
import PageLabel from 'layout/PageLabel/PageLabel';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;

  @media ${theme.deviceSizes.tablet} {
    height: auto;
    flex-wrap: wrap;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    min-height: 100vh;
    height: auto;
  }
`;

const MobileLabel = styled(Typography)`
  z-index: 99;
  position: absolute;
  top: ${getRem(89)};
  padding-left: ${getRem(75)};
  border-bottom: 1px solid ${theme.colors.White};
`;

const Label = styled(PageLabel)`


@media ${theme.deviceSizes.laptopL} {
    padding-left: ${getRem(52)};
    margin-left: ${getRem(-30)};
}

@media ${theme.deviceSizes.tablet} {
    padding-left: initial;
    margin-left: initial;
}

@media ${theme.deviceSizes.laptopLandScape} {
  padding-left: ${getRem(30)};
}
`;

const LeftSection = styled.div`
  display: flex;
  flex-basis: 38%;
  padding-bottom: ${getRem(143)};
  flex-direction: column;

  @media ${theme.deviceSizes.laptopL} {
    margin-left: 26px;
  }

  @media ${theme.deviceSizes.tablet} {
    order: 1;
    height: max-content;
    flex-basis: 100%;
    overflow: hidden;
    position: relative;
    margin-top: ${getRem(32)};
    padding-bottom: ${getRem(35)};
    margin-bottom: initial;
    margin-left: initial;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    margin-left: ${getRem(20)};
    padding-bottom: ${getRem(40)};
  }
`;

const RightSection = styled.div`
  flex-basis: 62%;
  overflow: hidden;
  background-size: 100% auto;

  @media ${theme.deviceSizes.tablet} {
    order: 0;
    flex-basis: 100%;
  }
`;

const Image = styled.img`
  width: 100%;
  overflow: hidden;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;

  @media ${theme.deviceSizes.tablet} {
    width: 100%;
    height: 335px;
    margin-top: ${getRem(67)};
    object-fit: cover;
    object-position: initial;
  }
`;

const TextBlock = styled.div`
  width: ${getRem(431)};
  margin-top: auto;
  margin-left: ${(props) => props.theme.spacers.primaryLeft};


  @media ${theme.deviceSizes.laptopL} {
    width: ${getRem(300)};
    margin-left: 5%;
  }

  @media ${theme.deviceSizes.tablet} {
    max-width: ${getRem(280)};
    margin-top: initial;
    margin-left: ${getRem(77)};
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    margin-top: ${getRem(30)};
    margin-left: 0;
  }
`;

const ContactButton = styled(Button)`
  justify-content: space-between;
  padding: 0 ${getRem(10)} 0 ${getRem(60)};
  margin-left: ${getRem(2)};

  &:hover {
    &::before {
      background-color: ${(props) => props.theme.colors.Blue};
    }
  }

  @media ${theme.deviceSizes.desktopS} {
    width: ${getRem(260)};
  }

  @media ${theme.deviceSizes.laptop} {
    margin: 0;
    width: ${getRem(230)};
  }

  @media ${theme.deviceSizes.tablet} {
    width: ${getRem(203)};
    height: ${getRem(39)};
    padding: 0 ${getRem(10)} 0 ${getRem(30)};
  }
`;

const CameraImage = styled.img`
  display: none;

  @media ${theme.deviceSizes.tablet} {
    width: 100%;
    right: -25%;
    display: block;
    position: relative;
    bottom: ${getRem(-20)};
    height: auto;
  }

  @media ${theme.deviceSizes.mobile} {
    margin-top: ${getRem(-106)};
    height: max-content;
    bottom: ${getRem(-90)};
    right: 0;
  }
`;

export const S = {
  MainContainer,
  LeftSection,
  RightSection,
  Image,
  TextBlock,
  ContactButton,
  CameraImage,
  MobileLabel,
  Label,
};
