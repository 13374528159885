import React from 'react';

import Card_1 from 'components/assets/images/Card1.png';
import Card_2 from 'components/assets/images/Card4.png';
import Card_3 from 'components/assets/images/Card3.png';
// import Card_4 from 'components/assets/images/Card4.png';

export const sliderItems = [
  {
    image: Card_1,
    titleText: 'One solution.',

    // titleText: (
    //   <>
    //     <p>One solution</p>
    //   </>
    //   // <>
    //   //   <p>Get all your parking management tools &</p>
    //   //   <p>data with zero5 solutions.</p>
    //   // </>
    // ),
    titleTextMobile: 'One solution.',
    // smallText: 'Get all your parking management tools & data with zero5. \n Enjoy a complete automated parking garage management solution.',
    smallText: (
      <>
        <p>Get all your parking management tools & data with zero5.</p>
        <p>Enjoy a complete automated parking garage management solution that does it all.</p>
      </>
    )
  },
  // {
  //   image: Card_2,
  //   titleText: 'Completely ticketless.',
  //   smallText: (
  //     <>
  //       <p>
  //         We utilize latest computer vision technology for ticketless parking entry.
  //       </p>
  //       <p>Our technology completely eliminates the inconvenience and inefficiency that comes with ticketing system.</p>
  //     </>
  //   ),
  //   smallTextMobile: (
  //     <>
  //       <p>
  //         We utilize latest computer vision technology for ticketless parking entry.
  //       </p>
  //       <p>Our technology completely eliminates the inconvenience and inefficiency that comes with ticketing system.</p>
  //     </>
  //   ),
  // },
  {
    image: Card_3,
    titleText: 'Fits your property.',
    smallText: (
      <>
        <p>
          With a range of parking management features, zero5 is designed to be
          versatile.
        </p>
        <p>
          We offer diverse parking management solutions that are hassle-free and fit your needs.
        </p>
        <p>Transform your parking space into a tech-enabled mobility hub.</p>
      </>
    ),
    titleTextMobile: 'Fits your property.',
    smallTextMobile: (
      <>
        <p>
          With a range of parking management features, zero5 is designed to be
          versatile.
        </p>
        {/* <br /> */}
        <p>
          We offer diverse parking management solutions that are hassle-free and fit your needs.
        </p>
      </>
    ),
  },
  {
    image: Card_2,
    titleText: 'The future is here.',
    // titleText: (
    //   <p>
    //     Transform your parking space into a tech-enabled mobility hub.
    //   </p>
    // ),
    // titleTextMobile: (
    //   <p>
    //     Transform your parking space into a tech-enabled mobility hub.
    //   </p>      
    //   // <p>
    //   //   Learn and transform your entire parking space into a tech-enabled
    //   //   mobility hub.
    //   //   <br />
    //   //   <br />
    //   //   Earn more revenue with your parking and give your clients high-end
    //   //   services.
    //   // </p>
    // ),
    titleTextMobile: 'The furue is here.',
    smallText: (
      <>
        <p>
          Learn how zero5 can help you earn more revenue and provide your customers with high-end services.      
        </p>
        <p>Discover how zero5's cloud-based web solution upgrades parking spaces from simple storage spaces into a future-proofed revenue generator.</p>
      </>
    ),
    smallTextMobile: (
      <>
        {/* <p>Discover how cloud-based web solutions upgrade parking spaces.</p>
        <br />
        <p>Transform from level 0 to 5 autonomy.</p> */}
        <p>
          Learn how zero5 can help you earn more revenue and provide your customers with high-end services.      
        </p>
        <p>Discover how zero5's cloud-based web solution upgrades parking spaces from simple storage spaces into a future-proofed revenue generator.</p>
        <p>Transform your parking space into a tech-enabled mobility hub.</p>
      </>
    ),    
  },
];
