import { useMemo } from 'react';
import { useNavigate } from 'react-router';

import Spacer from 'components/Spacer/Spacer';
import theme from 'theme/theme';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import CameraRender from '../../assets/CameraRender.png';
import { S } from './Styles';

const Description = () => {
  const navigate = useNavigate();
  const { width } = useViewport();

  const isMobile = useMemo(
    () => width < parseInt(viewportBreakpoints.tablet),
    [width]
  );

  return (
    <S.MainContainer>
      <S.TitleText
        text='Why we are here...'
        fontColor={theme.colors.White}
        fontSize={isMobile ? 'fz24' : 'fz28'}
        lineHeight={isMobile ? 26 : 34.5}
        fontWeight='500'
      />

      <Spacer height={53} />

      <S.DescriptionText
        fontColor={theme.colors.White}
        fontSize={isMobile ? 'fz14' : 'fz18'}
        lineHeight={isMobile ? '18' : '26'}
        fontWeight='300'
        text={
          <>
            New mobility technologies such as self-driving vehicles, EV's,
            e-scooters, and e-bikes are reshaping cities. But the infrastructure 
            that is needed to support these new movement is falling behind, especially parking.
            <br></br>
            <br></br>
            We started&nbsp;
            <span
              style={{
                color: theme.colors.Orange,
                fontWeight: '400',
                lineHeight: '7px',
              }}
            >
              zero5
            </span>{' '}
            because of our love for cars, transportation, and shared confusion around the lack of innovation in 
            parking. We were curious, and we digged deeper into why parking industry was falling behind.
            <br></br>
            <br></br>
            So, we took a dozen donuts and went garage to garage asking
            questions about every little detail about parking garage and lot operations.
            Armed with in-depth answers from real parking garage operators, managers, and owners, we created the next generation
            solution that leverages technology and data to change labor-intensive operations.
            <br></br>
            <br></br>
            Through automation, we provide a seamless parking experience for
            real estate owners, managers, parking operators, and drivers.
          </>
        }
      />

      <Spacer height={90} />

      {/* <S.ContactButton
        arrow={false}
        title='Contact Us'
        onClick={() => {
          navigate('/contact-us', {replace: true});
        }}
      /> */}

      <S.Image src={CameraRender} alt='Camera render image' />
    </S.MainContainer>
  );
};

export default Description;
