import { IconsType } from './icon.types';

const ArrowUp = (props: IconsType) => (
  <svg
    width={26}
    height={16}
    fill='#ffffff'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M26.371 9.426v5.978l-12.97-9.423L.43 15.404V9.426L13.4.002l12.97 9.424Z'
      fill={props.fill}
    />
  </svg>
);

export default ArrowUp;
