import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import TextInput from 'components/TextInput/TextInput';
import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import WithRouterAnimation from 'utils/HOC/WithRouterAnimation';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import { S } from './SLogin';

const Login = () => {
  const navigate = useNavigate();
  const { width } = useViewport();

  const isMobile = useMemo(
    () => width <= parseInt(viewportBreakpoints.tablet),
    [width]
  );


  const left = useMemo(() => width / 2, [width]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <S.Container>
      <S.Label
        fontSize='fz24'
        text='Client Login'
        fontWeight='300'
        lineHeight={isMobile ? '30' : '38'}
        left={left}
      />
      <div>
        <S.FormWrapper>
          <TextInput
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <S.PasswordInput>
            <TextInput
              type='password'
              value={password}
              placeholder='Password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <S.FogotPasswordLink href='#'>Forgot password?</S.FogotPasswordLink>
          </S.PasswordInput>

          <S.BottomContent>
            <S.SubmitBtn
              skew
              arrow
              title='Sign In'
              themeColor={theme.colors.Yellow}
              onClick={(e) => {
                e.preventDefault();

                navigate('/', {
                  replace: true,
                });
              }}
            />
            <S.TextContainer>
              <Typography
                fontWeight={300}
                fontSize='fz12'
                text={
                  <>
                    By signing in, you acknowledge that you have read and
                    understood our{' '}
                    <a style={{ color: theme.colors.LightGreen }} href='#'>
                      privacy notice.
                    </a>
                  </>
                }
              />
            </S.TextContainer>
          </S.BottomContent>
        </S.FormWrapper>

        <S.ContactUsBlock>
          <Typography
            fontWeight={'300'}
            text={
              <>
                Don't have an account?{' '}
                <a
                  style={{ color: theme.colors.Yellow, textDecoration: 'underline', fontWeight: '300' }}
                  href='#'
                >
                  Contact Us
                </a>
              </>
            }
          />
        </S.ContactUsBlock>
      </div>
    </S.Container>
  );
};

export default WithRouterAnimation(Login, {
  initial: {
    opacity: 0,
    x: '100%',
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    x: '-100%',
  },
});
