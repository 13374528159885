import theme from 'theme/theme';

import { IconsType } from './icon.types';

const ArrowRightSmall = ({
  width = 8,
  height = 13,
  color = theme.colors.White,
  ...props
}: IconsType) => (
  <svg fill='none' width={width} height={height} viewBox='0 0 8 13' {...props}>
    <path
      d='M3.18659 12.9462L0.183592 12.9462L4.91713 6.66032L0.183594 0.37439L3.18659 0.37439L7.92013 6.66032L3.18659 12.9462Z'
      fill={color}
    />
  </svg>
);

export default ArrowRightSmall;
