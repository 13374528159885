import { IconsType } from './icon.types';

const ArrowLeft = (props: IconsType) => (
  <svg
    width={10}
    height={17}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M3.882 17H0l6.118-8.5L0 0h3.882L10 8.5 3.882 17Z' fill='#fff' />
  </svg>
)

export default ArrowLeft;
