import styled from 'styled-components';

import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Container = styled.div`
  width: 100%;
  height: ${getRem(1600)};

  @media ${theme.deviceSizes.desktopL} {
    height: ${getRem(1150)};
  }

  @media ${theme.deviceSizes.desktopM} {
    height: ${getRem(900)};
  }

  @media ${theme.deviceSizes.desktopS} {
    height: ${getRem(750)};
  }

  @media ${theme.deviceSizes.laptopL} {
    height: ${getRem(650)};
  }

  @media ${theme.deviceSizes.laptop} {
    height: ${getRem(520)};
  }

  @media ${theme.deviceSizes.tablet} {
    height: auto;
    margin-bottom: 0;
  }

  @media ${theme.deviceSizes.laptopLandScape} {
    height: auto;
    margin-bottom: 0;
  }
`;

const PlayerContainer = styled.div`
  z-index: 1;
  width: 100%;
`;

const PlayerBackground = styled.div`
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const PlayIcon = styled.div`
  cursor: pointer;
  position: absolute;
`;

export const S = {
  Container,
  PlayerContainer,
  PlayerBackground,
  PlayIcon,
};
