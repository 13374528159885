import theme from 'theme/theme';

import { IconsType } from './icon.types';

const CloudIcon = ({
  width = 185,
  height = 126,
  color = theme.colors.Yellow,
  ...props
}: IconsType) => {
  return (
    <svg
      fill='none'
      width={width}
      height={height}
      viewBox='0 0 185 126'
      {...props}
    >
      <path
        d='M147.157 125.391H39.695A38.463 38.463 0 0 1 .882 89.474 37.848 37.848 0 0 1 27.034 51.24a44.39 44.39 0 0 1 .174-14.456C30.41 18.373 48.9 1.62 67.545.115a44.757 44.757 0 0 1 42.691 23.565h1.428a32.153 32.153 0 0 1 31.016 26.036 38.225 38.225 0 0 1 5.384-.367 38.005 38.005 0 0 1 35.659 24.742 38.004 38.004 0 0 1 2.343 15.113 38.464 38.464 0 0 1-38.909 36.187ZM70.999 9.726c-.9 0-1.807.039-2.721.116-14.379 1.062-29.085 14.437-31.575 28.7a35.243 35.243 0 0 0 .425 14.204 5.788 5.788 0 0 1-.734 4.439 5.79 5.79 0 0 1-3.724 2.586 28.236 28.236 0 0 0-22.157 29.182 28.817 28.817 0 0 0 29.182 26.788h107.462a28.812 28.812 0 0 0 29.259-27.02 28.343 28.343 0 0 0-1.744-11.27 28.344 28.344 0 0 0-15.415-16.17 28.335 28.335 0 0 0-11.173-2.282 28.699 28.699 0 0 0-7.18.907 5.79 5.79 0 0 1-4.941-.926 5.782 5.782 0 0 1-2.393-4.44 22.674 22.674 0 0 0-21.906-21.23c-.929.003-1.857.061-2.779.175a6.1 6.1 0 0 1-6.292-3.494A34.544 34.544 0 0 0 70.999 9.726Z'
        fill={color}
      />
    </svg>
  );
};

export default CloudIcon;
