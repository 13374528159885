import {useEffect, useState} from 'react';
import { useLocation } from 'react-router';

import Label from './components/Label';
import WhyUsWelcome from './components/WhyUsWelcome';
import useViewport from 'utils/hooks/useViewport';
import {usePrevious} from 'utils/hooks/usePrevious';

const WhyUs = () => {
  const { pathname } = useLocation();
  const { orientation } = useViewport();
  const [ isRerener, setIsRerender ] = useState(false);
  const previousOrientationStatus = usePrevious(orientation);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  useEffect(() => {
    if (isRerener) {
      setIsRerender(false);
    }
  }, [isRerener]);

  useEffect(() => {
    if (previousOrientationStatus !== undefined && previousOrientationStatus !== orientation) {
      setIsRerender(true);
    }
  }, [orientation])

  return (
    <>
      {!isRerener && (<>
        <Label />
        <WhyUsWelcome />
      </>)}
    </>
  );
};

export default WhyUs;
