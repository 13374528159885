import { useMemo } from 'react';

import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { viewportBreakpoints } from 'utils/constants';
import useViewport from 'utils/hooks/useViewport';

import UniqueSolutionBG from '../../assets/UniqueSolutionBG.png';
import NSlider from './Slider/NSlider';
import { S } from './SUniqueSolution';

const UniqueSolution = () => {

  const { width } = useViewport();

  const isMobile = useMemo(
    () => width < parseInt(viewportBreakpoints.tablet),
    [width]
  );
  return (
    <S.Container>
      <S.Image src={UniqueSolutionBG} />
      <S.PageLabelCard>
        <Typography
          fontSize={isMobile ? 'fz24' : 'fz36'}
          fontWeight='300'
          lineHeight={isMobile ? '26' : '44.5'}
          text={<>Our unique solution</>}
          fontColor={theme.colors.White}
        />
      </S.PageLabelCard>

      <NSlider />
    </S.Container>
  );
};

export default UniqueSolution;
