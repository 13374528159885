import styled, { CSSProperties, Theme } from 'styled-components';

import theme from 'theme/theme';

type TypographyProps = {
  reffer?: any;
  className?: string;
  text: string | JSX.Element;
  fontColor?: CSSProperties['color'];
  fontSize?: keyof Theme['fontSizes'];
  fontWeight?: CSSProperties['fontWeight'];
  lineHeight?: CSSProperties['lineHeight'];
};

const Typography = ({
  text,
  reffer,
  className,
  fontColor = theme.colors.Black,
  fontSize = 'fz18',
  fontWeight = 'regular',
  lineHeight = '26',
  ...props
}: TypographyProps) => {
  return (
    <Container
      ref={reffer}
      fontColor={fontColor}
      fontWeight={fontWeight}
      className={className}
      lineHeight={lineHeight}
      fontSize={theme.fontSizes[fontSize]}
      {...props}
    >
      {text}
    </Container>
  );
};

const Container = styled.p<{
  fontSize: string;
  fontColor: string;
  fontWeight: CSSProperties['fontWeight'];
  lineHeight: CSSProperties['lineHeight'];
}>`
  color: ${(props) => props.fontColor};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  line-height: ${(props) => props.lineHeight}px;
`;

export default Typography;
