import { motion } from 'framer-motion';
import styled from 'styled-components';

import Typography from 'components/Typography/Typography';
import theme from 'theme/theme';
import { getRem } from 'utils/functions';

const Container = styled(motion.div)`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: ${getRem(316)};
  height: ${getRem(600)};
  background-color: ${(props) => props.theme.colors.White};
  align-items: flex-start;
  justify-content: center;

  @media (min-width: 1925px) {
    /* width: 50%; */
  }

  @media ${theme.deviceSizes.laptopL} {
    padding-right: ${getRem(90)};
    width: ${getRem(400)};
  }

  @media ${theme.deviceSizes.laptopWithoutOrientation} {
    height: ${getRem(570)};
    width: ${getRem(400)};
  }

  @media ${theme.deviceSizes.tabletWithoutOrientation} {
    align-items: initial;
    height: ${getRem(440)};
    width: ${getRem(316)};
  }

  /* @media ${theme.deviceSizes.laptopLandScape} {
    padding-right: ${getRem(10)};
  } */
`;

const Description = styled(Typography)`
  opacity: 1;

  @media ${theme.deviceSizes.laptopL} {
    opacity: 0.6;
  }
`;

export const S = { Container, Description };
